import axios from 'axios'
import { accessToken } from './userfront-service'
import { devConsoleLog, httpMethods, buildErrorAndThrow } from './util'


const employeeRequest = method => async (params = {}) => {
    const employeeService = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}/api/v1/ops/employees`,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json'
        },
    })

    const { email } = params
    try {
        switch (method) {
            case httpMethods.get:
                return (await employeeService(email ? `/${email}` : undefined)).data

            case httpMethods.post:
            case httpMethods.patch:
                if (!email) buildErrorAndThrow('Employee POST/PATCH requires email to be set')
                await employeeService({
                    method,
                    url: method === httpMethods.patch ? `/${email}` : undefined,
                    data: params,
                })
                break

            case httpMethods.delete:
                if (!email) buildErrorAndThrow('Employee DELETE requires email to be set')
                await employeeService({ method, url: `/${email}` })
                break

            default:
                buildErrorAndThrow(`Method not yet implemented: ${method}`)
        }
    }
    catch (err) {
        devConsoleLog('employeeRequest', err.response ?? err)
        throw err
    }
}

const
    fetchEmployeeList = employeeRequest(httpMethods.get),
    createEmployee = (email, fullName, team) => employeeRequest(httpMethods.post)({
        email,
        fullName,
        team,
    }),
    modifyEmployee = (email, fullName, team) => employeeRequest(httpMethods.patch)({
        email,
        fullName,
        team,
    }),
    deleteEmployee = (email) => employeeRequest(httpMethods.delete)({
        email,
    })

async function isOpsTeam(employeeId) {
    try {
        const employee = await employeeRequest(httpMethods.get)({ email: employeeId })
        return (employee.team === 'ops')
    }
    catch (err) {
        return false
    }
}

export { fetchEmployeeList, createEmployee, modifyEmployee, deleteEmployee, isOpsTeam }
