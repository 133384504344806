import {HeaderView} from "../header/HeaderView";
import {Box, Typography,TextField} from "@mui/material";
import {FetchingInputs, Toast} from "../../../common/components";
import {FormContainer} from "../../interview/styles";
import {useInterview, useVerifyInterviewee} from "../../../common/hooks";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {toastTimeout} from "../../../common/constants";
import {InstructionsView} from "./InstructionsView";
import InterviewTestView from "./InterviewTestView";
import * as React from "react";
import {InstructionsModalView} from "./InstructionsModalView";
import '../recruitment.css';

const toastInitialState = {show: false, message: ''}

function VerifyEmailView() {
  useEffect(() => {
    document.title = 'Email Verification';
  }, []);
    const params = new URLSearchParams(window.location.search);
    const [interviewTest, requestInterviewTest] = useInterview();
    const [response, verifyIntervieweeEmail] = useVerifyInterviewee();
    const [toast, setToast] = useState(toastInitialState);
    const createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout);
    const [email, setEmail] = useState('');
    const [verified, setVerified] = useState(false);
    const [open, setOpen] = useState(false);
    const [startInterview, setStartInterview] = useState(false);

    const {register, handleSubmit} = useForm({shouldUseNativeValidation: true});

    const handleStartInterview = (res) => setStartInterview(res);
    const handleOpen = (event) => {
        if (event.target.innerText === 'INSTRUCTIONS') {
            setOpen(true);
        }
    }
    const handleClose = (event) => {
        if (event) {
            setOpen(false);
        }
    }
    const handleError = (error) => setToast({
        show: true,
        message: <><span style={{ color: 'red' }}>{error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data}</span>.</>
    });
    const handleSuccess = () => setToast({
        show: true,
        message: <><span style={{color: 'green'}}>Login Success</span></>
    });

    useEffect(() => {
        if (!verified && response?.verify?.result === 'success' && email) {
            setVerified(true);
            requestInterviewTest({email: email, uuid:params.get('userId')})
                .then(
                    handleSuccess,
                    handleError
                ).finally(createToastTimeout)
                .catch((error) => console.log(error))
        }
        if (!verified && response?.error && response?.verify?.length === 0 && email) {
            handleError(response?.error);
            setEmail('');
        }
    }, [response])

    function verifyEmail(data) {
        setEmail(data.email);
        verifyIntervieweeEmail({email: data.email.replace(/\s/g, ''), uuid:params.get('userId')})
            .then(
                handleSuccess,
                handleError
            ).finally(createToastTimeout)
            .catch((error) => console.log(error))
    }

    if (!verified) {
        return (
            <>
              <HeaderView>
              </HeaderView>
             
              <Box variant="outlined" style={{background: "#f6f6f6e8", }}>
                <FetchingInputs style={{}}>
                  <FormContainer>
                    <Typography variant="h6" component="h2" style={{textAlign: 'center', marginBottom: '1em'}}>
                        Email Verification
                    </Typography>
                    <form onSubmit={handleSubmit(verifyEmail)}>
                      <TextField
                        fullWidth 
                        helperText="Enter Email here "
                        label="Email"
                        name="email"
                        type={"email"}
                        {...register("email", {required: "Please enter your Email"})}
                      />
                      <br/>
                      <button className="verifyEmailButton" style={{
                          color: '#FFFFFF',
                          backgroundColor: '#ff0041',
                          border: '1px solid #ff0041',
                          marginTop: '1em',
                          width: '100%',
                      }} >Login</button>
                    </form>
                    <Toast visibility={toast.show}>{toast.message}</Toast>
                  </FormContainer>
                </FetchingInputs>
              </Box>
            </>
        )
    }
    if (verified && !startInterview) {
        return (
            <InstructionsView update={handleStartInterview}></InstructionsView>
        )
    }
    if (verified && startInterview) {
        return (
            <>
                <HeaderView/>
                
                <InterviewTestView
                    data={interviewTest} email={email} uuid={params.get('userId')} openModal={handleOpen}
                />
                <InstructionsModalView open={open} closeModel={handleClose}/>
            </>
        );
    }
}

export {VerifyEmailView}