import React, { useState, useEffect } from 'react'
import { Global } from '@emotion/react/macro'
import { useRedirect, useRoutes, navigate, A } from '@patched/hookrouter'
import { accessToken, currentUserId } from './api/userfront-service'
import { isOpsTeam } from './api/employee-service'
import { NotFound } from './common/components'
import router, { routes } from './router'
import globalStyle from './global-style'


function AccessLink() {
  const buildLink = (path, text) => (
    <p align="center">
      <A href={path}>{text}</A>
    </p>
  )

  if (window.location.pathname === routes.login) {
    return buildLink(routes.signUp, 'Sign Up')
  }

  if (window.location.pathname === routes.signUp) {
    return buildLink(routes.login, 'Log In')
  }

  return null
}

function handleRedirection(currentPath) {
  const
    hasAccessToken = !!accessToken,
    isHealthCheckPath = currentPath === routes.health,
    isInterviewPath = currentPath === routes.interview,
    isSignUpPath = currentPath === routes.signUp,
    isLoginPath = currentPath === routes.login,
    isResetPath = currentPath === routes.reset

  if (isHealthCheckPath || isInterviewPath)
    return null

  if (hasAccessToken && (isLoginPath || isSignUpPath || isResetPath)) 
    navigate(routes.seatMap, true)
  
  if (!hasAccessToken && !(isSignUpPath || isResetPath))
    navigate(routes.login, true)

}

function App() {
  const currentPath = window.location.pathname
  useRedirect('/dashboard', '/')
  handleRedirection(currentPath)

  const
    [isInOps, setIsInOps] = useState(false),
    resultRoute = useRoutes(router),
    hasPath = currentPath === '/dashboard' || Object.values(routes).includes(currentPath)
  useEffect(() => {
    isOpsTeam(currentUserId).then(setIsInOps)
  }, [])
  
  return !hasPath ? <NotFound /> : (
    <React.StrictMode>
      <Global styles={globalStyle} />
      {resultRoute?.(isInOps)}
      <AccessLink />
    </React.StrictMode>
  )
}

export default App
