import T from 'prop-types'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Button } from '../../common/components/StyledComponents'

const
  FormContainer = styled.div`
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    // padding: 20px;
  `,
  Label = styled.label`
    display: flex;
    flex-direction: column;
    margin: 5px 0;
  `,
  Input = styled.input`
    border-radius: 4px;
    border: 1px solid rgb(134, 134, 134);
    margin: 5px 0;
    height: 30px;
  `,
  Select = styled.select`
    border-radius: 4px;
    border: 1px solid rgb(134, 134, 134);
    margin: 5px 0;
    height: 35px;
  `,
  Paragraph = styled.p`
    margin-top: auto;
    margin-bottom: auto;
    padding: 5px 0;
  `,
  ErrorInfo = styled.p`
    color: #f5554f;
    margin: 0;
  `,
  ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `

const schema = yup.object({
	email: yup.string().email("Incorrect email").required("Email is required").lowercase("Email must be lowercase"),
	fullName: yup.string().min(3, "More than 3 letters").required(),
}).required()

function EmployeeForm({ fullName = '', email = '', team = '', submitLabel = 'Submit', onCancel, onSubmit }) {
	const { register, handleSubmit, setValue, formState: { errors } } = useForm({
			resolver: yupResolver(schema),
			defaultValues: { fullName, email, team },
	});

	useEffect(() => {
		setValue('fullName', fullName)
		setValue('email', email)
		setValue('team', team)
	}, [setValue, fullName, email, team]) //

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormContainer>
				<Label>
					<Paragraph>Full Name</Paragraph>
					<Input {...register('fullName')} />
					<ErrorInfo>{errors.fullName?.message}</ErrorInfo>
				</Label>
				<Label>
					<Paragraph>Email</Paragraph>
					<Input {...register('email', { required: true })} />
					<ErrorInfo>{errors.email?.message}</ErrorInfo>
				</Label>
				<Label>
					<Paragraph>Team</Paragraph>
					<Select {...register('team')}>
							<option value=""></option>
							<option value="ops">ops</option>
					</Select>
					<ErrorInfo>{errors.team?.message}</ErrorInfo>
				</Label>
				<ButtonsContainer>
					<Button mode="remove" onClick={onCancel}>Cancel</Button>
					<Button type="submit">{submitLabel}</Button>
				</ButtonsContainer>
			</FormContainer>
		</form>
	)
}

EmployeeForm.propTypes = {
	fullName: T.string,
	email: T.string,
	team: T.string,
	submitValue: T.string,
	onCancel: T.func.isRequired,
	onSubmit: T.func.isRequired,
}

export default EmployeeForm