import './HeaderView.css';

function HeaderView() {
    return (
      <>
        <div className='nav'>
          <div className='nav__contents'>
            <div className='nav__links nav__links--header'>
              <a href='/'><img className='nav__logo' src="https://zinkworks.com/wp-content/uploads/2021/09/Zinkworks.png" alt="Zinkworks. logo"/></a>
              </div>
          </div>
        </div>
    </>
    )
}

export {HeaderView}