import dayjs from 'dayjs'
import styled from '@emotion/styled/macro'
import Seat from './Seat'


const
    Item = styled.div`
        display: flex;
        align-items: center;
        margin-left: 1rem;
    `,
    Label = styled.label`
        margin-left: .5rem;
        font-weight: bold;
    `

const
    emptyFunc = () => { },
    BookList = ({ bookings }) => (
        <>

            {bookings?.map(({ date, seat: { seatNumber }, officeName }, idx) => (
                <Item key={idx}>
                    <div>{officeName}</div>
                    <Seat
                        id={date}
                        isSelected={true}
                        onAddSeat={emptyFunc}
                        onRemoveSeat={emptyFunc}
                        label={seatNumber}
                    />
                    <div>{dayjs(date).toDate().toDateString()}</div>
                </Item>
            ))}
        </>
    )

export default BookList
