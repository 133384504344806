import { useReducer, useEffect } from 'react'
import dayjs from 'dayjs'
import { buildErrorAndThrow } from '../../api/util'
import { fetchBookings, postSeatBook, deleteSeatBook, patchSeatBook, patchChangeMonitors, fetchUserBookings, fetchOffices, postGroupBooking } from '../../api/booking-service'
import { fetchEmployeeList, createEmployee, modifyEmployee, deleteEmployee } from '../../api/employee-service'
import { useContext } from "react"
import OfficeContext from '../../views/seatmap/OfficeContext'
import { fetchIntervieweeList } from '../../api/recruitment-service'
import { fetchInterviewTest, postInterviewTest } from '../../api/interview-service'
import { createInterviewee, removeInterviewee, patchIntervieweeRequest } from '../../api/interviewee-service'
import { fetchIntervieweeByEmail } from "../../api/interviewee-email-service";
import { VerifyIntervieweeEmail } from "../../api/interviewee-verify-email-service";
import { fetchResultByEmail } from "../../api/result-email-service";

const
    buildActions = dispatch => ({
        startedRequest: () => dispatch({ type: 'STARTED_REQUEST' }),
        succeedRequest: body => dispatch({ type: 'SUCCEED_REQUEST', payload: body }),
        failedRequest: error => dispatch({ type: 'FAILED_REQUEST', payload: error }),
    }),
    buildStatus = status => ({
        isIdle: status === 'idle',
        isLoading: status === 'pending',
        isResolved: status === 'resolved',
        isRejected: status === 'rejected',
    }),
    initialState = { status: 'idle' }

function reducer(_, action) {
    const { type, payload } = action
    switch (type) {
        case 'STARTED_REQUEST':
            return { status: 'pending' }
        case 'SUCCEED_REQUEST':
            return { status: 'resolved', response: payload }
        case 'FAILED_REQUEST':
            return { status: 'rejected', error: payload }
        case 'RESET_REQUEST':
            return initialState
        default:
            throw new Error(`Unknown action type: ${type}`)
    }
}

function useSeatMapBookings(currentUserId) {
    const [seatMap, dispatchSeatMap] = useReducer(reducer, initialState)
    const { officeName, setOfficeName } = useContext(OfficeContext)
    function requestBookings(isoDate = dayjs().format('YYYY-MM-DD'), selectedofficeName) {
        const { startedRequest, succeedRequest, failedRequest } = buildActions(dispatchSeatMap)
        startedRequest()
        const bookingLocation = selectedofficeName ? selectedofficeName : officeName
        fetchBookings(isoDate, currentUserId, bookingLocation).then(succeedRequest, failedRequest)
    }

    useEffect(requestBookings, [currentUserId])

    return [
        {
            status: buildStatus(seatMap.status),
            error: seatMap.error,
            ...seatMap.response,
        },
        requestBookings
    ]
}

function useUserBookings(currentUserId) {
    const [userBookings, dispatchUserBookings] = useReducer(reducer, initialState)
    const { officeName, setOfficeName } = useContext(OfficeContext)
    function requestUserBookings(selectedofficeName) {
        const { startedRequest, succeedRequest, failedRequest } = buildActions(dispatchUserBookings)
        const bookingLocation = selectedofficeName ? selectedofficeName : officeName
        startedRequest()
        fetchUserBookings(currentUserId, bookingLocation).then(succeedRequest, failedRequest)
    }

    return [
        {
            bookings: userBookings.response,
            status: buildStatus(userBookings.status),
            error: userBookings.error?.response,
        },
        requestUserBookings
    ]
}
function useBlockSeat() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function manageSeatOps(officeId, seatNumber, available) {
        startedRequest()
        return patchSeatBook(officeId, seatNumber, available).then(succeed, failed)
    }
    return [manageSeatOps]
}

function useChangeMonitor() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function manageMonitorOps(officeId, seatNumber, monitors) {
        startedRequest()
        return patchChangeMonitors(officeId, seatNumber, monitors).then(succeed, failed)
    }
    return [manageMonitorOps]
}

function useBookSeat() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function addSeatBook(isoDate, seatNumber, employeeId) {
        startedRequest()
        return postSeatBook(isoDate, seatNumber, employeeId).then(succeed, failed)
    }

    function removeSeatBook(isoDate, seatNumber, employeeId) {
        startedRequest()
        return deleteSeatBook(isoDate, seatNumber, employeeId).then(succeed, failed)
    }

    function manageSeatOps(officeId, seatNumber, available) {
        startedRequest()
        return patchSeatBook(officeId, seatNumber, available).then(succeed, failed)
    }

    function requestGroupSeatBookings(startDate, endDate, email, seatIds) {
        startedRequest()
        return postGroupBooking(startDate, endDate, email, seatIds).then(succeed, failed)
    }

    return [
        {
            status: buildStatus(state.status),
            error: state.error?.response,
        },
        addSeatBook,
        removeSeatBook,
        manageSeatOps,
        requestGroupSeatBookings,

    ]
}

function useEmployeeList() {
    const [state, dispatch] = useReducer(reducer, initialState)

    function requestEmployeeList() {
        const { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)
        startedRequest()
        fetchEmployeeList().then(succeedRequest, failedRequest)
    }

    useEffect(requestEmployeeList, [])

    return [
        {
            employees: state.response ?? [],
            status: buildStatus(state.status),
            error: state.error,
        },
        requestEmployeeList
    ]
}

function useEmployeeProfile() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function addEmployee({ email, fullName, team }) {
        startedRequest()
        return createEmployee(email, fullName, team).then(succeedRequest, failed)
    }

    function updateEmployee({ email, fullName, team }) {
        startedRequest()
        return modifyEmployee(email, fullName, team).then(succeedRequest, failed)
    }

    function removeEmployee({ email }) {
        startedRequest()
        return deleteEmployee(email).then(succeedRequest, failed)
    }

    return [
        buildStatus(state.status),
        addEmployee,
        updateEmployee,
        removeEmployee
    ]
}
function useOfficeSelection() {
    const [offices, setOffice] = useReducer(reducer, initialState)

    function requestOfficeSelection() {
        const { startedRequest, succeedRequest, failedRequest } = buildActions(setOffice)
        startedRequest()
        fetchOffices().then(succeedRequest, failedRequest)
    }
    useEffect(requestOfficeSelection, [])

    return [
        {
            offices: offices.response ?? [],
            status: buildStatus(offices.status),
            error: offices.error
        },
        requestOfficeSelection

    ]

}

function useRecruitment() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function requestIntervieweeDetails() {
        startedRequest()
        return fetchIntervieweeList().then(succeedRequest, failed)
    }

    useEffect(requestIntervieweeDetails, [])

    return [
        {
            interviewees: state.response ?? [],
            status: buildStatus(state.status),
            error: state.error,
        },
        requestIntervieweeDetails
    ]
}
function useIntervieweeEmail() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function requestIntervieweeByEmail(email) {
        startedRequest()
        return fetchIntervieweeByEmail(email).then(succeedRequest, failed)
    }

    useEffect(requestIntervieweeByEmail, [])

    return [
        {
            interviewees: state.response ?? [],
            status: buildStatus(state.status),
            error: state.error,
        },
        requestIntervieweeByEmail
    ]
}
function useVerifyInterviewee() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function verifyIntervieweeEmail({ email, uuid }) {
        startedRequest()
        return VerifyIntervieweeEmail(email, uuid).then(succeedRequest, failed)
    }

    return [
        {
            verify: state.response ?? [],
            status: buildStatus(state.status),
            error: state.error,
        },
        verifyIntervieweeEmail
    ]
}
function useIntervieweeProfile() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function addInterviewee({ email, fullName, uuid }) {
        startedRequest()
        return createInterviewee(email, fullName, uuid).then(succeedRequest, failed)
    }
    function deleteInterviewee({ email }) {
        startedRequest()
        return removeInterviewee(email).then(succeedRequest, failed)
    }

    function patchInterviewee({ email, uuid, scheduledTest }) {
        startedRequest()
        return patchIntervieweeRequest(email, uuid, scheduledTest).then(succeedRequest, failed)

    }
    return {
        buildStatus: buildStatus(state.status),
        addInterviewee,
        deleteInterviewee,
        patchInterviewee
    }
}

function useInterview() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function requestInterviewTestDetails({ email, uuid }) {
        startedRequest()
        return fetchInterviewTest(email, uuid).then(succeedRequest, failed)
    }

    return [
        {
            interviewTest: state.response ?? [],
            status: buildStatus(state.status),
            error: state.error,
        },
        requestInterviewTestDetails
    ]
}


function usePostInterview() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        [count] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function addInterviewTest(email, uuid, count, catalog) {
        startedRequest()
        return postInterviewTest(email, uuid, count, catalog).then(succeedRequest, failed)
    }

    return [
        {
            status: buildStatus(state.status),
            error: state.error?.response,
        },
        addInterviewTest
    ]
}
function useResultEmail() {
    const
        [state, dispatch] = useReducer(reducer, initialState),
        { startedRequest, succeedRequest, failedRequest } = buildActions(dispatch)

    const
        succeed = data => succeedRequest(data) ?? data,
        failed = reason => failedRequest(reason) ?? buildErrorAndThrow(reason)

    function requestResultByEmail(email) {
        startedRequest()
        return fetchResultByEmail(email).then(succeedRequest, failed)
    }

    useEffect(requestResultByEmail, [])

    return [
        {
            interviewees: state.response ?? [],
            status: buildStatus(state.status),
            error: state.error,
        },
        requestResultByEmail
    ]
}


export { useSeatMapBookings, useBookSeat, useBlockSeat, useChangeMonitor, useUserBookings, useEmployeeList, useEmployeeProfile, useOfficeSelection, useRecruitment, useIntervieweeEmail, useIntervieweeProfile, usePostInterview, useInterview, useResultEmail, useVerifyInterviewee }
