import styled from '@emotion/styled/macro'

const
    Container = styled.div`
        display: flex;
        position: absalute;
        bottom: 10%;
        margin-top: 15px;
    `,
    Item = styled.div`
        display: flex;
        align-items: center;
        margin-left: 20px;
    `,
    Square = styled.div`
        background-color: ${({ color }) => color};
        box-sizing: border-box;
        width: 12px;
        height: 12px;
    `,
    Label = styled.label`
        font-size: smaller;
        margin: 5px;
    `


const Legend = () => (
    <Container>
        <div id="legend">
            <Item>
                <Square color="#f5554f" />
                <div>Unavailable</div>
            </Item>
            <Item>
                <Square color="#e0e0e0" />
                <div>Reserved</div>
            </Item>
        </div>
        <div>
            <Item>
                <Square color="#4fc3f7" />
                <div>Available</div>
            </Item>
            <Item>
                <Square color="#4caf50" />
                <div>Selected</div>
            </Item>
        </div>
    </Container>
)

export default Legend