import styled from '@emotion/styled/macro'
import { useState, useEffect } from 'react'

const
    Container = styled.div`
        z-index: 11;
        padding: 1rem;
        right: 0;
        top: 0;
        position: fixed;
        box-sizing: border-box;
        display: block;
    `,
    ToastBody = styled.div`
        width: 350px;
        max-width: 100%;
        font-size: .875rem;
        pointer-events: auto;
        background-color: rgba(255,255,255,.85);
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.1);
        box-sizing: border-box;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        border-radius: .25rem;
        display: flex;
        opacity: ${({ isVisible }) => isVisible ? 'initial' : 0};
        transition: opacity .30s linear;
    `,
    ToastMsg = styled.div`
        padding: .75rem;
        word-wrap: break-word;
        box-sizing: border-box;
        display: block;
    `,
    ButtonClose = styled.button`
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        margin: auto;
        margin-right: .5rem;
        padding: .25em .25em;
        color: #000;
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        border: 0;
        border-radius: .25rem;
        opacity: .5;
    `;

const Toast = ({ visibility, children }) => {
    const [state, setState] = useState(false)

    useEffect(() => setState(visibility), [visibility])

    return (
        <Container>
            <ToastBody isVisible={state}>
                <ToastMsg>{children}</ToastMsg>
                <ButtonClose onClick={() => setState(false)} />
            </ToastBody>
        </Container>
    )
}

export default Toast