import {HeaderView} from "../header/HeaderView";
import {
    Avatar,
    Card,
    Checkbox,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Button from "@mui/material/Button";
import {DisclaimerModalView} from "./DisclaimerModalView";
import {useState, useEffect} from "react";
import './InterviewTest.css';

function InstructionsView(props) {
  useEffect(() => {
    document.title = 'Test Instructions';
  }, []);
    const handleStart = () => props.update(true);
    const [open, setOpen] = useState(false);
    const [disableStart, setDisableStart] = useState(true);
    const handleOpen = (event) => {
        if (event.target.innerText === 'Terms of Data Usage') {
            setOpen(true);
        }
    }
    const handleClose = (event) => {
        if (event) {
            setOpen(false);
        }
    }
    const handleCheckbox = (event) => {
        if (event.currentTarget.checked === true) {
            setDisableStart(false);
        }
        if (event.currentTarget.checked === false) {
            setDisableStart(true);
        }
    }
    return (
        <>
            <HeaderView />
            <Typography variant="h6" component="h2" style={{textAlign: 'center'}}>
                Please read the instructions below carefully
            </Typography>
            <Grid container spacing={2} style={{marginBottom: "15px"}}>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            width: "100%",
                            height: 50,
                            backgroundColor: '#f6f6f6e8',
                            border: '1px solid #0000001f'
                        }}
                    ><List sx={{
                        height: "-webkit-fill-available",
                        padding: "0px 0px 0px 8px",
                        width: '100%', maxWidth: 360,
                        bgcolor: '#f6f6f6e8'
                    }}>
                        <ListItem style={{padding: 0}}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FormatListBulletedIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText style={{margin: "3px"}} primary="10 Questions" secondary="10 Point for correct answer"/>
                        </ListItem>
                    </List>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            width: "100%",
                            height: 50,
                            backgroundColor: '#f6f6f6e8',
                            border: '1px solid #0000001f'
                        }}
                    ><List sx={{
                        height: "-webkit-fill-available",
                        padding: "0px 0px 0px 8px",
                        width: '100%', maxWidth: 360,
                        bgcolor: '#f6f6f6e8'
                    }}>
                        <ListItem style={{padding: 0}}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AccessTimeFilledIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText style={{margin: "3px"}} primary="5 Min 50 Sec" secondary="Total duration of test"/>
                        </ListItem>
                    </List>
                    </Box>
                </Grid>
            </Grid>
            <Card variant="outlined" style={{background: "#f6f6f6e8", height: "15rem"}}>
                <ul>
                    <li>You have 35 seconds to answer each question.</li>
                    <li>Once you have selected the answer you can navigate to another question clicking Next button
                        or
                    </li>
                    <li>By default, once the 35 sec question timer is elapsed, you will be automatically moved to
                        the next question
                    </li>
                    <li>Once you answer Q10 you may submit your test. Please note once the 5 min 50 sec time has elapsed
                        your test will automatically be submitted regardless whether you completed or not
                    </li>
                    <li>Once you are ready you can click the start button.</li>
                </ul>
            </Card>
            <DisclaimerModalView open={open} closeModel={handleClose}/>
            <div style={{display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px"}}>
                <span><Checkbox onChange={handleCheckbox}/>I accept &nbsp;
                <Link  style={{cursor: 'pointer'}} onClick={handleOpen}>Terms of Data Usage</Link></span>
            </div>
            <div className="InterviewStartButton"
              style={{display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px"}}
            >
                <Button style={{
                    color: '#FFFFFF',
                    backgroundColor: '#00a90f',
                    border: '1px solid #00a90f'
                }} disabled={disableStart} onClick={handleStart} variant="contained">Start</Button>
            </div>
        </>
    )
}

export {InstructionsView}