import { schema } from 'normalizr'


const
    seat = new schema.Entity('seats' , {}),
    employee = new schema.Entity('employees', {}, { idAttribute: 'email' }),
    bookingList = {
        reservations: [{ seat, employee }],
        rows: new schema.Values([seat]),
    },
    employeeList = [employee]

export { bookingList, employeeList }