import React, { useState } from 'react';
import T from 'prop-types';
import styled from '@emotion/styled/macro';
import Modal from 'react-modal';
import MonitorIcon from '@mui/icons-material/Monitor';


const Container = styled.div`
  background-color: ${({ isEnabled, isSelected, isReserved }) => {
    if (!isEnabled) return '#f5554f';
    if (isReserved) return '#e0e0e0';
    if (isSelected) return '#4caf50';

    return '#4fc3f7';
  }};
  cursor: ${({ isOps, isReserved, isEnabled }) =>
    isOps ? 'pointer' : isReserved || !isEnabled ? 'not-allowed' : 'pointer'};
  height: 35px;
  width: 52px;
  flex: none;
  color: white;  
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 1px;
  margin-left: 1px;
  margin-top: 12px;
  margin-bottom: 12px;

  ${({ isEnabled, isSelected, isReserved }) => {
    if (isEnabled && !isSelected && !isReserved)
      return '&:hover { background-color: #03a9f4; }';
  }}
`;

const HoverEffect = styled.div`
  position: relative;

  &:hover {
    .style {
      opacity: 1;
      visibility: visible;
    }

    .icon {
      visibility: visible;
    }
  }

  .style {
    position: absolute;
    text-align: center;
    transform: translateX(10%);
    color: #000000;
    padding: 1px;
    border-radius: 5px;
    visibility: hidden;
    

    .icon {
      position: relative;
      top: -77px;
      right: -3.9px;
      cursor: pointer;
      height: 30px;
    }
  }
`;

const CustomModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

function Seat({ isEnabled = true, isReserved = false, isSelected = false, label, isOps = false, onAddSeat, onRemoveSeat, monitors, fullName }) {
  const isAvailable = isEnabled && !isReserved;
  const handleClick = () =>
    isOps ? onAddSeat() : isAvailable && (isSelected ? onRemoveSeat() : onAddSeat());

  const [isHovered, setIsHovered] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <HoverEffect onMouseEnter={() => setIsHovered(true)}>
      <Container isEnabled={isEnabled} isSelected={isSelected} isReserved={isReserved} onClick={handleClick} isOps={isOps}>
        {label}
      </Container>
      {isHovered && (
        <div className="style">
          {/* <p>Monitors: {monitors}</p> */}
          <button className="icon" data-testid='monitor-icon-button' onClick={() => setIsModalOpen(true)}>
          <MonitorIcon></MonitorIcon>
          </button>
        </div>
      )}

      <CustomModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <p>Additional Information:</p>
          <p>Monitors: {monitors}</p>
        { fullName && <p>Employee: {fullName}</p>}
          <button onClick={() => setIsModalOpen(false)}>Close</button>
        </ModalContent>
      </CustomModal>
    </HoverEffect>
  );
}

Seat.propTypes = {
  label: T.oneOfType([T.string, T.number]).isRequired,
  onAddSeat: T.func.isRequired,
  onRemoveSeat: T.func.isRequired,
  monitors: T.oneOfType([T.string, T.number]),
  fullName: T.oneOfType([T.string, T.number]),
};

export default Seat;
