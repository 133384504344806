import Userfront from '@userfront/react'


Userfront.init('6nzz6yn7')

const
    LoginForm = Userfront.build({ toolId: 'dddabr' }),
    PwdResetForm = Userfront.build({ toolId: 'roonrb' }),
    SignupForm = Userfront.build({ toolId: 'arrbnl' })


export const
    LoginView = () => <LoginForm />,
    SignupView = () => <SignupForm />,
    PwdResetView = () => <PwdResetForm />,
    // This method has a bug where called more than one time
    // in different imports is returning an object containing
    // the access token instead of the token itself.
    // To isolate the issue I create this file to manage all call 
    // to Userfront (single source of truth).
    accessToken = Userfront.accessToken(), 
    currentUserId = Userfront.user.email,
    systemLogout = Userfront.logout