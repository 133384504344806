import {FetchingInputs} from '../../../common/components'
import {useIntervieweeProfile} from '../../../common/hooks'
import {useState} from 'react'
import {toastTimeout} from '../../../common/constants'
import {useForm} from 'react-hook-form'
import {Toast} from '../../../common/components'
import {Card, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {v4 as uuidv4} from 'uuid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import T from "prop-types";

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const toastInitialState = {show: false, message: ''}

function AddIntervieweeView({open, closeModel}) {
  
    const
      {addInterviewee} = useIntervieweeProfile(),
      [toast, setToast] = useState(toastInitialState),
      createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout);

    const {register, handleSubmit} = useForm({shouldUseNativeValidation: true});

    const handleError = (error) => setToast({
      show: true,
      message: <>Error creating new interviewee: <span style={{color: 'red'}}>{error.message}</span> <br/>Check if email already in use.<br/></>
    });

    function handleCreate(interviewee) {
      addInterviewee(interviewee)
        .then(
          () => {
            setToast({
                show: true,
                message: <>New interviewee '<strong>{interviewee.fullName}</strong>'' created!</>
            })
						document.getElementById("addInterviewee").reset();
            window.location.reload();
          },
          handleError
        ).finally(createToastTimeout)
        .catch((error) => console.log(error))
    }

    return (
        <>
          <Toast visibility={toast.show}>{toast.message}</Toast>
          <Modal
            open={open}
            onClose={closeModel}
            aria-labelledby="Add Interviewee"
          >
            <Box sx={style}>
              <Typography variant="h6" component="h2" style={{textAlign: 'center', marginBottom: '.5em'}}>
                  Add Interviewee
              </Typography>
              <Card variant="outlined" style={{background: "#f6f6f6e8", paddingBottom: "1.5em"}}>
                <FetchingInputs>
                  <form id={'addInterviewee'} onSubmit={handleSubmit(handleCreate)}>
                    <label>Full name:</label><br/>
                    <input 
                      {...register("fullName", {required: "Please enter candidate's full name."})} 
                      placeholder="Enter full name here" 
                    />
                    <br/>
                    <label>Email:</label><br/>
                    <input
                      {...register("email", {required: "Please enter candidate's email."})}
                      placeholder="Enter email here"
                    />
                    <input hidden={true} {...register("uuid", { value: uuidv4() })}/>
                    <br/>
                    <Box display='flex' width='1' mt={3} flexDirection='row' justifyContent='center' boxShadow='none'>
                      <button
                        style={{
                          color: '#FFFFFF',
                          backgroundColor: '#ff0041',
                          border: '1px solid #ff0041',
													borderRadius: '4px',
													textTransform: 'uppercase',
													cursor: 'pointer',
													display: 'flex',
													justifyContent: 'center',
													alignItems:'center',
													fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
													fontWeight: '500',
													fontSize: '0.875rem',
													lineHeight: '1.75',
													letterSpacing: '0.02857em',
													transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                        }}
                        onClick={handleCreate}> Confirm
                      </button>
                      <Button 
                        style={{
                          color: '#FFFFFF',
                          backgroundColor: '#c4bfbe',
                          border: '1px solid #c4bfbe',
                          marginLeft: '10px'
                        }}
                       onClick={closeModel}>
                          Cancel
                      </Button>
                    </Box>
                  </form>
                </FetchingInputs>
            </Card>
            </Box>
          </Modal>
        </>
    )
}

AddIntervieweeView.propTypes = {
  open: T.bool.isRequired,
  closeModel: T.any
}
export default AddIntervieweeView
