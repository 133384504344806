import {useIntervieweeProfile, useRecruitment} from '../../../common/hooks'
import IntervieweeList from '../RecruitmentList'
import {Typography} from "@mui/material";
import {routes} from "../../../router";
import AddIntervieweeLinkList from "../../../common/components/AddIntervieweeLink";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import {useState} from "react";
import {ActivateTestModelView} from "./ActivateTestModelView";
import * as React from "react";
import {toastTimeout} from "../../../common/constants";
import {Toast} from "../../../common/components";
import {RemoveIntervieweeModalView} from "./RemoveIntervieweeModalView";
import HrIcon from '../../../common/components/Icons/HrIcon';
import '../recruitment.css';
import AddIntervieweeView from '../addinterviewee/AddIntervieweeView';
import {v4 as uuidv4} from 'uuid';

const toastInitialState = {show: false, message: ''}

function DashboardView() {
    const [intervieweeList, requestInterviewees] = useRecruitment()
    let isActivateTestDisabled = true;
    let isActivateRemoveDisabled = true;
    const [disableActivateTest, setActivateDisableTest] = useState(true);
    const [disableRemove, setDisableRemove] = useState(true);
    const [selectedRow, setSelectedRow] = useState([]);
    const [openActivateTest, setOpenActivateTest] = useState(false);
    const [openReActivateTest, setOpenReActivateTest] = useState(false);
    const [openAddInterviewee, setOpenAddInterviewee] = useState(false);
    const [openRemoveInterviewee, setOpenRemoveInterviewee] = useState(false);
    const [toast, setToast] = useState(toastInitialState);
    const createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout);
    const handleError = (error) => setToast({
      show: true,
      message: <>Error reactivating test for interviewee: <span style={{color: 'red'}}>{error.message}</span> <br/></>
    });

    const handleOpen = (event) => {
      if (event.target.innerText === 'ACTIVATE TEST') {
        setOpenActivateTest(true);
      }
      if (event.target.innerText === 'REMOVE INTERVIEWEE') {
        setOpenRemoveInterviewee(true);
      } 
      if (event.target.innerText === 'ADD INTERVIEWEE') {
        setOpenAddInterviewee(true);
      }
    }
    const handleCloseActivateTest = (event) => {
      if (event) {
        setOpenActivateTest(false);
      }
    }
    const handleCloseReActivateTest = (event) => {
      if (event) {
        setOpenReActivateTest(false);
      }
    }
    const handleCloseRemoveInterviewee = (event) => {
      if (event) {
        setOpenRemoveInterviewee(false);
      }
    }
    const handleCloseAddInterviewee = (event) => {
      if (event) {
        setOpenAddInterviewee(false);
      }
    }
    const handleMessage = () => setToast({
      show: true,
      message: <>Reactivate test as interviewee has already given the test previously </>
    });

    const selectedRows = (values) => {
      let valuesExist = values && values.length > 0;
      if (valuesExist) {
        setDisableRemove(false);
        setSelectedRow(values);
      } else {
        setDisableRemove(true);
        setSelectedRow([]);
      }
      if (valuesExist) {
        setActivateDisableTest(false);
        setSelectedRow(values);
      }
      isActivateRemoveDisabled = (valuesExist) ? setActivateDisableTest(false) : setActivateDisableTest(true);
    }

    return (
      <>
        <div className='nav'>
          <div className='nav__contents'>
            <div className='nav__links nav__links--recruitment'>
              <a href='/'><img className='nav__logo' src="https://zinkworks.com/wp-content/uploads/2021/09/Zinkworks.png" alt="Zinkworks. logo"/></a>
              <AddIntervieweeLinkList
                show={true}
                paths={[
                  {route: '#', icon: PersonAddAlt1Icon, label: 'Add Interviewee', isDisabled: false},
                  {route: '#', icon: PersonRemoveIcon, label: 'Remove Interviewee', isDisabled: disableRemove},
                  {route: '#', icon: FactCheckIcon, label: 'Activate Test', isDisabled: disableActivateTest},
                  {route: routes.admin, icon: ManageAccountsIcon, label: 'Admin', isDisabled: false},
                ]}
                openModal={handleOpen}
              >
              </AddIntervieweeLinkList>
            </div>
            <div className='admin'>
              <HrIcon /><span>HR</span>
            </div>
          </div>
        </div>
        
        <Typography variant="h6" component="h2" style={{textAlign: 'center', margin:'1em 0'}}>
            Interviewee List
        </Typography>
        <IntervieweeList data={intervieweeList} selectedRows={selectedRows} />
        <ActivateTestModelView open={openActivateTest} closeModel={handleCloseActivateTest} selectedRow={selectedRow}/>
        <ActivateTestModelView open={openReActivateTest} closeModel={handleCloseReActivateTest} selectedRow={selectedRow}/>
        <RemoveIntervieweeModalView open={openRemoveInterviewee} closeModel={handleCloseRemoveInterviewee} selectedRow={selectedRow}></RemoveIntervieweeModalView>
        <AddIntervieweeView open={openAddInterviewee} closeModel={handleCloseAddInterviewee}></AddIntervieweeView>
        <Toast visibility={toast.show}>{toast.message}</Toast>
      </>
    )
}

export {DashboardView}