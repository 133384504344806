import styled from '@emotion/styled/macro'

const
  headTop = 0,
  headHeight = 100

export const
  Header = styled.header`
    background: whitesmoke;
    width: 100%;
    height: ${headHeight}px;
    position: fixed;
    top: ${headTop}px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: 1px solid #dee2e6;
    z-index: 111;
  `,  
  Sidebar = styled.nav`
    background-color: white;
    width: 300px;
    height: 100%;
    position: absolute;    
    top: ${headTop + headHeight + 2}px;
    left: 0;
    border-right: 1px solid #dee2e6;
    overflow-y: auto;
  `,
  FetchingMain = styled.main`
    margin-top: ${headTop + headHeight}px;
    margin-left: 300px;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  FetchingInputs = styled.main`
    margin-top: 4%;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    line-height: 2;
    width: 100%;
  `,
  ResolvedMain = styled.main`
    margin-top: ${headTop + headHeight + 5}px;
    margin-left: 300px;
    height: 480px;
    display: flex;
    flex-flow: column wrap;
  `,
  RejectedMain = styled.main`
    margin-top: ${headTop + headHeight}px;
    margin-left: 300px;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
  `,
  Button = styled.button`
    background-color: ${({ mode }) => mode === 'remove' ? '#f5554f' : '#4fc3f7'};
    border-radius: 4px;
    border: none;
    color: #ffffff;
    height: 35px;
    width: 80px;
    margin: 12px 4px;
    cursor: pointer;
    flex: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 12px 4px;
    :hover {
      background-color: ${({ mode }) => mode === 'remove' ? '#cf2e2e' : '#03a9f4'};
    }
  `,
  ButtonConfirm = styled.button`
    background-color: ${({ mode }) => mode === 'remove' ? '#f5554f' : '#4fc3f7'};
    border-radius: 4px;
    border: none;
    color: #ffffff;
    height: 35px;
    width: 80px;
    margin: 12px 4px;
    cursor: pointer;
    flex: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 12px 4px;
    position: absolute;
    right:    100px;
    bottom:   1px;
    :hover {
      background-color: ${({ mode }) => mode === 'remove' ? '#cf2e2e' : '#03a9f4'};
    }
  `,
  ButtonZinkworks = styled.button`
    color: #FFFFFF;
    background-color: rgb(255, 0, 65);
    border: 1px solid rgb(255, 0, 65);
    border-radius: 2px;
    display: flex;
  `,
  OfficeMain = styled.main`
    margin-top: ${headTop + headHeight + 5}px;
    margin-left: 300px;
    height: 15px;
    display: flex;
    flex-flow: column wrap;
  `,
  ZinkworksLogo = <img style={{maxHeight: "2em"}} src="https://zinkworks.com/wp-content/uploads/2021/09/Zinkworks.png" alt="Zinkworks. logo"/>
