import styled from '@emotion/styled/macro'
import { LoadingDots } from '../../common/components'
import BookList from './BookList'
import { element } from 'prop-types'
import './index.css'

const
    Container = styled.div`
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
    `,
    Title = styled.div`
        margin-left: 1.1rem;
        font-size: small;
        font-weight: bold;
    `,
    List = styled.div`
        overflow-y: auto;
        border: solid #dee2e6;
        border-width: 1px;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        position: relative;
        box-sizing: border-box;
        height: 15rem;
        margin-left: 1rem;
        margin-right: 1rem;
    `,
    SideEffect = styled.div`
        margin-top: 50px;
        margin-left: 40px;
    `

const BoxWrapper = element => (
    <Container>
        <Title>Your bookings</Title>
        <List>{element}</List>
    </Container>
)



function BookListStatus({ data }) {
    const { bookings, status, error } = data

    if (status.isLoading)
        return BoxWrapper(
            <SideEffect>
                <LoadingDots length={5}>Loading</LoadingDots>
                <progress />
            </SideEffect>
        )

    if (status.isRejected)
        return BoxWrapper(
            <SideEffect>
                <div style={{ color: 'red' }}>
                    User booking list request failed! {error.response?.data ?? error.message}
                </div>
            </SideEffect>
        )

    if (status.isResolved)
        return BoxWrapper(
            <BookList bookings={bookings} />
        )

    return null
}

export default BookListStatus
