const
    devConsoleLog = (process.env.REACT_APP_ENV === 'development') ? console.log : () => {},
    httpMethods = {
        get: 'get',
        post: 'post',
        patch: 'patch',
        delete: 'delete',
    },
    buildErrorAndThrow = error => { throw (typeof error === 'object') ? error : new Error(error) }

export { devConsoleLog, httpMethods, buildErrorAndThrow }