import axios from 'axios'
import { accessToken } from './userfront-service'
import { devConsoleLog, httpMethods, buildErrorAndThrow } from './util'

const intervieweeListRequest = method => async (params = {}) => {
    const recruitmentService = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}/api/v1/hr/interview/interviewee`,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json'
        },
    })
    
    try {
        switch (method) {
            case httpMethods.get:
                return (await recruitmentService()).data           

            default:
                buildErrorAndThrow(`Method not yet implemented: ${method}`)
        }
    }
    catch (err) {
        devConsoleLog('intervieweeListRequest', err.response ?? err)
        throw err
    }
}

const
   fetchIntervieweeList = intervieweeListRequest(httpMethods.get) 

export { fetchIntervieweeList}