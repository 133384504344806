import axios from 'axios'
import { accessToken } from './userfront-service'
import { devConsoleLog, httpMethods, buildErrorAndThrow } from './util'
import {encode as base64_encode} from "base-64";

const interviewTestRequest = method => async (params = {}) => {
    let data
    const interviewservice = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}/api/v1/ext/interview`,
        withCredentials: true,
        headers: {
            Authorization: `Basic ${base64_encode(params.email+':'+params.uuid)}`,
            Accept: 'application/json'
        },
    })

    try {

        switch (method) {
            case httpMethods.get:
                ({ data } = await interviewservice({
                    method,
                    url: `/test/${params.uuid}`,
                }))
            return data
            case httpMethods.post:
                ({ data } = await interviewservice({
                    method,
                    url :  `/result`,
                    data: {
                        //replace interviewee email
                        intervieweeEmail: params.email,
                        score: params.count,
                        dateTaken: new Date().toISOString(),
                        level: "test",
                        testCategory: params.catalog,
                    },
                }))
                break
            default:
                buildErrorAndThrow(`Method not yet implemented: ${method}`)
            return data
        }
    }
    catch (err) {
        devConsoleLog('interviewTestRequest', err.response ?? err)
        throw err
    }
}

const
   fetchInterviewTest = (email, uuid) => interviewTestRequest(httpMethods.get)({email, uuid}),
   postInterviewTest = (email, uuid, count, catalog) => interviewTestRequest(httpMethods.post)({email, uuid, count, catalog})

export { fetchInterviewTest, postInterviewTest}