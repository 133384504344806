import { useState } from 'react'
import T from 'prop-types'
import EmployeeForm from './EmployeeForm'

function EmployeeProfile({ employee, onRemoveClick, onUpdateClick, show }) {
  const
    [showForm, setShowForm] = useState(show),
    exposeForm = () => setShowForm(true),
    hideForm = () => setShowForm(false)
      
  if (!employee) return null

  if (showForm) return (
    <EmployeeForm 
			{...employee}
			submitLabel='Update'
			onSubmit={employee => hideForm() || onUpdateClick(employee)}
			onCancel={hideForm}
      onEditClick={exposeForm}
      onRemoveClick={() => onRemoveClick(employee)}
      data-testid = "employee-form"
    />
  )
  return null;
}

EmployeeProfile.propTypes = {
  employee: T.shape({
		fullName: T.string,
		email: T.string,
		team: T.string
  }),
  onRemoveClick: T.func.isRequired,
  onUpdateClick: T.func.isRequired,
}

export default EmployeeProfile  