import axios from 'axios'
import { accessToken } from './userfront-service'
import { devConsoleLog, httpMethods, buildErrorAndThrow } from './util'

const intervieweeRequestByEmail = method => async (params = {}) => {
    const intervieweeEmailService = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}/api/v1/hr/interview/interviewee`,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json'
        },
    })
    try {
        switch (method) {
            case httpMethods.get:
                return (await intervieweeEmailService('email' ? `/${params}` : undefined)).data
            default:
                buildErrorAndThrow(`Method not yet implemented: ${method}`)
        }
    }
    catch (err) {
        devConsoleLog('intervieweeRequestByEmail', err.response ?? err)
        throw err
    }
}

const
    fetchIntervieweeByEmail = intervieweeRequestByEmail(httpMethods.get)

export { fetchIntervieweeByEmail}