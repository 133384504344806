import {useEffect, useRef, useState} from 'react';

const useCountdown = (countingTime) => {
    let timer = useRef(null);
    const [counter, setCounter] = useState(countingTime);
    useEffect(() => {
        if (counter > 0) {
            timer.current =
                counter > 0 && setInterval(() => setCounter(prev => prev - 1), 1000);
        }
        return () =>{
            clearInterval(timer.current);
        }
    }, []);
    const reset = () => {
        if(counter === countingTime) {
            return;
        }
        setCounter(countingTime)
    }
    const stop = () => {
        clearInterval(timer.current);
    }
    return {counter, reset, stop}
};

export { useCountdown };
