const MeetingRoom = () => {
    
    return (
        <div className='meetingRoom'>
            <div className="meetingRoom">Meeting Room</div>
        </div>
    );
}

export default MeetingRoom;
