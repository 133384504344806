import { useEffect, useState } from 'react'
import T from 'prop-types'
import { useImmer } from 'use-immer'
import { currentUserId } from '../../api/userfront-service'
import { devConsoleLog } from '../../api/util'
import { toastTimeout } from '../../common/constants'
import { useBookSeat } from '../../common/hooks'
import OfficeContext from './OfficeContext'
import { FetchingMain, ResolvedMain, RejectedMain, LoadingDots, Toast } from '../../common/components'
import SeatMap from './SeatMap'
import BookStatusProvider from './BookStatusProvider'
import React, { useContext } from "react";
import MeetingRoom from '../../common/components/MeetingRoom'
import OfficeContainer from './OfficeContainer'



let toastMsg = null

let
  updateSeatList = (totalBooked, seatMap) => draft => {
    draft.seatMap = seatMap
    draft.totalBooked = totalBooked
    draft.toastVisibility = { show: true }
  },
  setIsReserved = (rowId, seatNumber) => draft => {
    const
      row = draft.seatMap.find(row => row.id === rowId),
      seat = row?.seats.find(seat => seat.seatNumber === seatNumber)
    if (seat) seat.isReserved = true
  },
  hideToast = setSeatList => () => setSeatList(
    draft => { draft.toastVisibility = { show: false } }
  ),
  onSuccessBook = (setSeatList, onSuccessOpr, message) => ({ totalBooked, seatMap }) => {
    toastMsg = message
    setSeatList(updateSeatList(totalBooked, seatMap))
    setTimeout(hideToast(setSeatList), toastTimeout)
    onSuccessOpr()
  }

const handleAddSelectSeat = (isoDate, bookingLimit, totalBooked, setSeatList, addSeatBook, onSuccessOpr, seatList, selectedSeats, setSelectedSeats, isOps) => (rowId, seatId) => {
  if (isOps) {
    let chosenSeats = selectedSeats;
    if (!chosenSeats.includes(seatId)) {
      chosenSeats.push(seatId);
    } else {
      chosenSeats.splice(chosenSeats.indexOf(seatId), 1);  //deleting
    }
    setSelectedSeats(chosenSeats);
  }
  else {
    if (totalBooked >= bookingLimit) {
      alert(`Max number of reservations per user reached (limit: ${bookingLimit})!`)
    } else {
      addSeatBook(isoDate, seatId, currentUserId)
        .then(
          onSuccessBook(setSeatList, onSuccessOpr, <>Seat '{seatId}' in Row '{rowId}' <strong>reserved</strong> successfully!</>),
          error => {
            console.error('handleAddSelectSeat', error)
            if (error.response?.status === 403) setSeatList(setIsReserved(rowId, seatId))
          }
        )
    }
  }
}

const handleRemoveSelectSeat = (isoDate, setSeatList, removeSeatBook, onSuccessOpr) => (rowId, seatNumber) => {
  removeSeatBook(isoDate, seatNumber, currentUserId)
    .then(
      onSuccessBook(setSeatList, onSuccessOpr, <>Seat '{seatNumber}' in Row '{rowId}' <strong>unreserved</strong> successfully!</>),
      error => console.error('handleRemoveSelectSeat', error)
    )
}
function SeatMapContainer({
  data: { isoDate, bookingLimit, totalBooked, seatMap, status, error },
  onSuccessOpr,
  isOps,
  selectedSeats,
  setSelectedSeats,
  selectedOffice
}) {
  const { officeName, setOfficeName } = useContext(OfficeContext)
  const
    [seatBookStatus, addSeatBook, removeSeatBook] = useBookSeat(),
    [seatList, setSeatList] = useImmer({})

  useEffect(() => seatMap && setSeatList({ totalBooked, seatMap }),
    [totalBooked, seatMap, setSeatList],
  )

  const
    addSelectSeat = handleAddSelectSeat(isoDate, bookingLimit, seatList.totalBooked, setSeatList, addSeatBook, onSuccessOpr, seatList, selectedSeats, setSelectedSeats, isOps),
    removeSelectSeat = handleRemoveSelectSeat(isoDate, setSeatList, removeSeatBook, onSuccessOpr)

  if (status.isLoading)
    return (
      <FetchingMain>
        <label>
          <LoadingDots length={5}>Loading</LoadingDots>
          <progress />
        </label>
      </FetchingMain>
    )

  if (status.isRejected)
    return (
      <RejectedMain>
        <p>Error on fetching the seat map: {error.response?.data ?? error.message}</p>
      </RejectedMain>
    )
  const renderMeetingRoom = selectedOffice == 'Athlone' ? true : false;

  if (status.isResolved && seatList.seatMap?.length)
    return (
      <ResolvedMain style={{ width: '70%', height: '550px' }}>
        {renderMeetingRoom && <MeetingRoom />}
        <BookStatusProvider status={seatBookStatus}>
          <SeatMap
            data={seatList.seatMap}
            onAddSeat={addSelectSeat}
            onRemoveSeat={removeSelectSeat}
            isOps={isOps}
          />
        </BookStatusProvider>
        {/*
        Follow same solution as in admin index.
        Remove the state from the toast and control it presentation in this component instead.
        Like has been done in finally in the admin index file
        */}
        <Toast visibility={seatList.toastVisibility?.show}>{toastMsg}</Toast>
      </ResolvedMain>
    )
  return null
}
SeatMapContainer.propTypes = {
  data: T.object.isRequired,
}
export default SeatMapContainer