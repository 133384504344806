import axios from 'axios'
import { devConsoleLog, httpMethods, buildErrorAndThrow } from './util'
import { encode as base64_encode } from 'base-64';

const intervieweeVerifyEmail = (method, email, uuid) => async (params = {email, uuid}) => {
    const intervieweeVerifyEmailService = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}/api/v1/ext/interview/verifyemail`,
        withCredentials: true,
        headers: {
            Authorization: `Basic ${base64_encode(email+':'+uuid)}`,
            Accept: 'application/json'
        },
    })
    try {
        switch (method) {
            case httpMethods.get:
                return (await intervieweeVerifyEmailService('email' ? `/${params.email}` : undefined)).data
            default:
                buildErrorAndThrow(`Method not yet implemented: ${method}`)
        }
    }
    catch (err) {
        devConsoleLog('verifyIntervieweeEmail', err.response ?? err)
        throw err
    }
}
const VerifyIntervieweeEmail = (email, uuid) => intervieweeVerifyEmail(httpMethods.get, email, uuid)({
    email,
    uuid
})
export { VerifyIntervieweeEmail }
