import "./DialogBox.css";
import CloseIcon from "../../../common/components/Icons/CloseIcon";
import EmployeeForm from "../EmployeeForm";
import EmployeeProfile from "../EmployeeProfile";

export default function EmployeeFormDialog(props){
  const onClose = (e) => {
    props.onClose && props.onClose(e);
  };  
  let newEmployee = false;
  if(props.employee == null){
    newEmployee = true;
  }
  if (!props.show) {
    return null;
  }
  return (
    <div className="modalContainer">
      <span className="backdrop"></span>
      <div className="overlay">
        <div className="modal" id="modal">
          <div className="modalHeader">
            {newEmployee ? 
              <p>Create Employee</p> : 
              <p>Edit Employee</p> 
            }
            <button className="IconButton" onClick={onClose}><CloseIcon ></CloseIcon></button>
          </div>
          <div className="content">
            {newEmployee
              ? <EmployeeForm
                  submitLabel="Create"
                  onCancel={props.onClose}
                  onSubmit={props.onSubmit}
                />
              : <EmployeeProfile
                  show={true}
                  employee={props.employee}
                  onUpdateClick={props.onUpdate}
                />
              }
          </div>
        </div>
      </div>
    </div>
  );
}