import { useState, useEffect } from 'react'
import { toastTimeout } from '../../common/constants'
import { LoadingView, Toast, } from '../../common/components'
import { useEmployeeList, useEmployeeProfile } from '../../common/hooks'
import EmployeeList from './EmployeeList'
import NavBar  from './NavBar/NavBar';
import './index.css';
import DeleteConfirmation from './DialogBox/DeleteConfirmation'
import EmployeeFormDialog from './DialogBox/EmployeeFormDialog'


const toastInitialState = { show: false, message: '' }

function AdminView({ isOps }) {
  useEffect(() => {
    document.title = 'Admin';
  }, []);
	const
		[employeeList, requestEmployeeList] = useEmployeeList(),
		[removeStatus, addEmployee, updateEmployee, removeEmployee] = useEmployeeProfile(),
		[selectedEmployee, setSelectedEmployee] = useState(null),
		[newEmployee, setNewEmployee] = useState(false),
		[toast, setToast] = useState(toastInitialState),
		hideEmployeeForm = () => setNewEmployee(false),
		[showDeleteDialog, setDeleteDialog] = useState(false),
		[showEmployeeFormDialog, setEmployeeFormDialog] = useState(false)

    const
			createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout),
			handleError = (error) => setToast({
				show: true,
				message: <>Error removing employee: <span style={{ color: 'red' }}>{error.message}</span>.</>
			}),
			handleCreate = (employee) => {
				addEmployee(employee).then(
					() => {
						hideEmployeeForm()
						setToast({
							show: true,
							message: <>New employee '<strong>{employee.fullName}</strong>'' created!</>
						})
						requestEmployeeList()
					},
					handleError
				).finally(createToastTimeout);
				setEmployeeFormDialog(!showEmployeeFormDialog);
			},
			handleSelect = (employee) => {
				hideEmployeeForm()
				setSelectedEmployee(employee)
			},
			handleUpdate = (employee) => {
				updateEmployee(employee).then(
					() => {
						requestEmployeeList()
						setSelectedEmployee(null)
						setToast({
							show: true,
							message: <>Employee '<strong>{employee.fullName}</strong>'' updated!</>
						})
						requestEmployeeList()
					},
					handleError
				).finally(createToastTimeout);
				setEmployeeFormDialog(!showEmployeeFormDialog);
			},

			handleRemoveConfirmation = () => {
				removeEmployee(selectedEmployee).then(
					() => {
						setSelectedEmployee(null)
						setToast({
							show: true,
							message: <>Employee '<strong>{selectedEmployee.fullName}</strong>' removed!</>
						})
						requestEmployeeList()
					},
					handleError
				).finally(createToastTimeout);
				setDeleteDialog(!showDeleteDialog);
			},
			handleDeleteDialogModal = (employee) => {
				setDeleteDialog(!showDeleteDialog);
				setSelectedEmployee(employee);
			},
			handleEmployeeFormDialog = (employee) => {
				if(employee == null){
				setSelectedEmployee(null);
				}
				else{setSelectedEmployee(employee);
				}
				setEmployeeFormDialog(!showEmployeeFormDialog);
			},
      handleToast = (message) => {
        setToast({
          show: true,
          message:message
        })
        createToastTimeout();
      }
        
     return !isOps ? <LoadingView /> : (
			<>
				<NavBar isOps={isOps} handleToast={handleToast}/>
				<div className="employee__dashboard">
					<EmployeeList
						data={employeeList}
						onItemClick={handleEmployeeFormDialog}
						onAddClick={handleEmployeeFormDialog}
						onRemoveClick={handleDeleteDialogModal}
					/>
				</div>
				<DeleteConfirmation employee={selectedEmployee} onConfirm={handleRemoveConfirmation} show={showDeleteDialog} hideModal={handleDeleteDialogModal}/>
				<EmployeeFormDialog employee={selectedEmployee} show={showEmployeeFormDialog} onClose={handleEmployeeFormDialog} onSubmit={handleCreate} onUpdate={handleUpdate}/>
				{removeStatus.isLoading && <LoadingView />}
				<Toast visibility={toast.show}>{toast.message}</Toast>
			</>
    )
}

export { AdminView }