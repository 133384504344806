import styled from '@emotion/styled/macro';

export const FormContainer = styled.div`
        margin: 100px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3em;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: -5px 10px 20px 3px rgb(0 0 0 / 6%);
    `

export const  Label = styled.label`
        display: block;
        flex-direction: column;
        margin: 5px 0;
        font-size: 24px;
    `

export const Button = styled.button`
      background: rgb(6, 14, 131);
      border: none;
      color: #fff;
      border-radius: 10px;
      font-size: 24px;
      padding: 10px;
      margin: 10px 10px 10px 0px;
      box-shadow: 0px 0px 1px 1px rgb(0, 0, 0);
      :hover {
        background: rgb(6, 60, 131);
      }
      :active {
        transform: translateY(2px);
      }
    `

export const  InputRadioButton = styled.input`
        left: 0.5rem;
        top: 0.2rem;
        display: inline-block;
        border: 0.1rem solid black;
        background: white;
        transform: scale(1.3); 
    `
