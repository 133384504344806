import axios from 'axios'
import { accessToken } from './userfront-service'
import {buildErrorAndThrow, devConsoleLog, httpMethods} from './util'


const intervieweeRequest = method => async (params = {}) => {
    const intervieweeService = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}`,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
        }
    })
    try {
        switch (method) {
            case httpMethods.post:
                return (await intervieweeService({
                    method,
                    url: '/api/v1/hr/interview/interviewee',
                    data: params,
                })).data
            case httpMethods.delete:
                return (await intervieweeService({
                    method,
                    url: `/api/v1/hr/interview/interviewee/${params.email}`,
                })).data
              case httpMethods.patch:
                return (await intervieweeService({
                    method,
                    url: `/api/v1/hr/interview/interviewee/${params.email}`,
                    data: params,
                })).data
            default:
                buildErrorAndThrow(`Method not yet implemented: ${method}`)
        }
    }
    catch (err) {
        devConsoleLog('intervieweeRequest', err.response ?? err)
        throw err
    }
}

const createInterviewee = (email, fullName, uuid) => intervieweeRequest(httpMethods.post)({
        email,
        fullName,
        uuid
    })

const removeInterviewee = (email) => intervieweeRequest(httpMethods.delete)({
    email
})

const patchIntervieweeRequest = (email, uuid, scheduledTest) => intervieweeRequest(httpMethods.patch)({
  email,
  uuid,
  scheduledTest
})


export { createInterviewee, removeInterviewee, patchIntervieweeRequest }
