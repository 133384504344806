import T from 'prop-types'
import styled from '@emotion/styled/macro'
import { FetchingMain, RejectedMain, LoadingDots } from '../../common/components'
import RemoveIcon from '../../common/components/Icons/RemoveIcon'
import PencilIcon from '../../common/components/Icons/PencilIcon'
import DeleteConfirmation from './DialogBox/DeleteConfirmation'
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React, { useState } from "react";
import SearchIcon from '../../common/components/Icons/SearchIcon'

const
  TableContainer = styled.div``,
  HeadContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px;
  `
function EmployeeList({ data: { status, error, employees }, onItemClick, onAddClick, onRemoveClick, props }) {
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState(employees);
  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: 'team',
      headerName: 'Team',
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: 'editEmployee',
      type: 'actions',
      headerName: 'Edit',
      flex: 1,
      editable: false,
      disableClickEventBubbling: true,
      sortable: false,
      getActions: (params) => [
        <GridActionsCellItem
          className='EditEmployee'
          icon={<PencilIcon/>}
          label='Edit'
          onClick={() => onEditButton(params)}
        />
      ],
    },
    {
      field: 'deleteEmployee',
      type: 'actions',
      headerName: 'Remove',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<RemoveIcon/>}
          label='Delete'
          onClick={() => onRemoveButton(params)}
        />
      ],
      editable: false,
      sortable: false,
      disableClickEventBubbling: true,
    }
  ];

	const requestSearch = (e) => {
    setSearchText(e.target.value);
    const searchRegex = new RegExp(`.*${e.target.value}.*`, "ig");
    const filteredRows = employees.filter((o) => {
      return Object.keys(o).some((k) => {
        return searchRegex.test(o[k].toString());
      });
    });
    setTableData(filteredRows);
  };

	const onEditButton = (props) => {
		onItemClick(props.row);
	}
	const onRemoveButton = (props) => {
		onRemoveClick(props.row);

	}


  if (status.isLoading) return (
    <FetchingMain>
      <label>
        <LoadingDots length={5}>Loading</LoadingDots>
        <progress />
      </label>
    </FetchingMain>
  )

  if (status.isRejected) return (
    <RejectedMain>
        <p>Error fetching employee list: {error.response?.data ?? error.message}</p>
    </RejectedMain>
  )

  if (status.isResolved) 
    if (employees && employees.length > 0) {
      for (const key in employees) {
          employees[key].id = key + 1;
      }
  }

  return (
    <>
      <div className='EmployeeList'>
        <TableContainer>

          <HeadContainer>
            <div className='SearchBar'>
              <div className='SearchInput'>
                <input
                  onChange={(e) => requestSearch(e)}
                  value={searchText}
                  type="text"
                  placeholder="Search an employee"
                  name="search form"
                  required
                />
                <div className="SearchBarIcon">
                  <SearchIcon />
                </div>
              </div>
            </div>
            <div className='BaseButton' onClick={() => onAddClick(null)}>
              <svg className='add-people' width="24" height="24" version="1.1">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10ZM10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11ZM4.00214 18.8381C5.85316 19.5871 7.87719 20 10.0001 20C12.1229 20 14.1469 19.5871 15.9979 18.8381C15.9121 15.5993 13.2596 13 10 13C6.74043 13 4.08794 15.5992 4.00214 18.8381ZM3 19C3 19.3111 3.194 19.5859 3.48127 19.7053C5.48876 20.5395 7.69063 21 10.0001 21C12.3094 21 14.5112 20.5395 16.5187 19.7054C16.806 19.586 17 19.3111 17 19C17 15.134 13.866 12 10 12C6.13401 12 3 15.134 3 19ZM19 8C19 7.72386 18.7761 7.5 18.5 7.5C18.2239 7.5 18 7.72386 18 8V10H16C15.7239 10 15.5 10.2239 15.5 10.5C15.5 10.7761 15.7239 11 16 11H18V13C18 13.2761 18.2239 13.5 18.5 13.5C18.7761 13.5 19 13.2761 19 13V11H21C21.2761 11 21.5 10.7761 21.5 10.5C21.5 10.2239 21.2761 10 21 10H19V8Z" fill="currentColor" />,
              </svg> 
              Add employee
            </div>
          </HeadContainer>

					<Box sx={{height: 800, width: '100%'}}>
						<DataGrid
							rowHeight={52}
							rows={(searchText === '') ? employees : tableData}
							columns={columns}
							pageSize={13}
							rowsPerPageOptions={[13]}
							sx={{
								boxShadow: 2,
								'& .MuiDataGrid-cell:hover': {
									color: 'primary.main',
								},
							}}
						/>
					</Box>

        </TableContainer>          
      </div>
			<>
				<DeleteConfirmation />
			</>
    </>     
  )
}

EmployeeList.propTypes = {
  data: T.shape({
      employees: T.array.isRequired,
      status: T.object.isRequired,
      error: T.object,
  }),
}

export default EmployeeList