import axios from 'axios'
import { normalize } from 'normalizr'
import { accessToken } from './userfront-service'
import { devConsoleLog, httpMethods } from './util'
import { bookingList } from './booking-schema'




const
    bookingService = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}/api/v1/booking`,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json'
        },
    }),
    isEqualsIgnoreCase = (str1, str2) => str1?.toLocaleLowerCase() === str2?.toLocaleLowerCase();

const
    adminManageSeatService = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}/api/v1/ops`,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json'
        },
    })

function buildSeatMap(data, isoDate, employeeId) {
    // Entities contains information on employees and seats
    // Result contains rows and seats

    const { entities, result } = data;
    const reservations = result.reservations.reduce((prev, curr) => ({
        ...prev,
        totalBooked: (curr.employee === employeeId) ? ++prev.totalBooked : prev.totalBooked,
        [typeof curr.seat === 'object' ? curr.seat.id : curr.seat]: {
            isReserved: !isEqualsIgnoreCase(curr.employee, employeeId),
            isSelected: curr.employee === employeeId,
            fullName: entities.employees[curr.employee]?.fullName || null,
        }
    }), { totalBooked: 0 });


    const officeId = entities.seats[1]?.officeId;
    const modifiedRows = officeId === 1 ? modifyRows(result.rows) : result.rows;
    return {
        maxSeats: result.maxSeats,
        isoDate,
        officename: result.name,
        bookingLimit: 1,
        totalBooked: reservations.totalBooked,


        seatMap: Object.keys(modifiedRows).map(rowId => ({
            id: rowId,
            label: rowId,
            seats: modifiedRows[rowId].map(seatId => ({
                id: seatId,
                row: rowId,
                label: entities.seats[seatId].seatNumber,
                officeId: entities.seats[seatId].officeId,
                isEnabled: entities.seats[seatId].available,
                isReserved: !!reservations[seatId]?.isReserved,
                isSelected: !!reservations[seatId]?.isSelected,
                monitors: entities.seats[seatId].monitors,
                fullName: reservations[seatId]?.fullName || null,
            })),
        })),
    };
}

function modifyRows(rows) {
    const modifiedRows = { ...rows };
    const reverseSeatOrder = (seats) => seats.slice().reverse();

    ['C', 'E', 'L', 'J', 'N'].forEach(rowId => {
        if (modifiedRows[rowId]) {
            modifiedRows[rowId] = reverseSeatOrder(modifiedRows[rowId])
        }
    });

    const swapRows = (row1, row2) => {
        const temp = modifiedRows[row1];
        modifiedRows[row1] = modifiedRows[row2]
        modifiedRows[row2] = temp;
    };
    swapRows('I', 'N');
    swapRows('J', 'M');
    swapRows('K', 'L');

        return modifiedRows;
    }
    

const blockSeatsRequest = method => async params => {
    try {
        const { officeId, seatId, available } = params;
        let data
        switch (method) {
            case httpMethods.patch:
                ({ data } = await adminManageSeatService({
                    method,
                    data: {
                        'seatNumbers': seatId,
                        'available': available
                    },
                    url: `/office/${officeId}/seats`
                }))
                break
            default:
                throw new Error(`Method not yet implemented: ${method}`)
        }
        return data
    }
    catch (err) {
        devConsoleLog('bookRequest', err.response ?? err)
        throw err
    }
}

const changeMonitorsRequest = method => async params => {
    try {
        const { officeId, seatId, monitors } = params;
        let data
        switch (method) {
            case httpMethods.patch:
                ({ data } = await adminManageSeatService({
                    method,
                    data: {
                        'seatNumbers': seatId,
                        'monitors': monitors
                    },
                    url: `/office/${officeId}/monitors`
                }))
                break
            default:
                throw new Error(`Method not yet implemented: ${method}`)
        }
        return data
    }
    catch (err) {
        devConsoleLog('bookRequest', err.response ?? err)
        throw err
    }
}

const groupBookingRequest = method => async params => {
    try {
        const { startDate, endDate, email, seatIds } = params;
        let data
        switch (method) {
            case httpMethods.post:
                ({ data } = await bookingService({
                    method,
                    data: {
                        'startDate': startDate,
                        'endDate': endDate,
                        'email': email,
                        'seatIds': seatIds
                    },
                    url: `/group`
                }))
                break
            default:
                throw new Error(`Method not yet implemented: ${method}`)
        }
        return data
    }
    catch (err) {
        devConsoleLog('groupBookingRequest', err.response ?? err)
        throw err
    }
}
const bookRequest = method => async params => {
    try {
        const { isoDate, seatId, employeeId, officeName, officeId, available, monitors } = params
        let data
        switch (method) {
            case httpMethods.get:
                ({ data } = await bookingService({
                    method,
                    url: `/location/${officeName}/date/${isoDate}`,
                }))
                break
            case httpMethods.post:
                ({ data } = await bookingService({
                    method,
                    data: {
                        date: isoDate,
                        email: employeeId,
                        seatId: seatId
                    },
                }))
                break
            case httpMethods.patch:
                ({ data } = await adminManageSeatService({
                    method,
                    data: {
                        seatNumbers: seatId,
                        available: available,
                        monitors: monitors
                    },
                    url: `/office/${officeId}/seats`
                }))
                break
            case httpMethods.patch:
                ({ data } = await adminManageSeatService({
                    method,
                    data: {
                        seatNumbers: seatId,
                        monitors: monitors
                    },
                    url: `/office/${officeId}/monitors`
                }))
                break
            case httpMethods.delete:
                ({ data } = await bookingService({
                    method,
                    url: `/${isoDate}/${employeeId}/${seatId}`,
                }))
                break

            default:
                throw new Error(`Method not yet implemented: ${method}`)
        }

        return buildSeatMap(normalize(data, bookingList), isoDate, employeeId)
    }
    catch (err) {
        devConsoleLog('bookRequest', err.response ?? err)
        throw err
    }
}

const
    fetchBookings = (isoDate, employeeId, officeName) => bookRequest(httpMethods.get)({
        isoDate,
        employeeId,
        officeName

    }),
    postSeatBook = (isoDate, seatId, employeeId) => bookRequest(httpMethods.post)({
        isoDate,
        seatId,
        employeeId

    }),
    deleteSeatBook = (isoDate, seatId, employeeId) => bookRequest(httpMethods.delete)({
        isoDate,
        seatId,
        employeeId
    }),
    patchSeatBook = (officeId, seatId, available) => blockSeatsRequest(httpMethods.patch)({
        officeId,
        seatId,
        available
    }),
    patchChangeMonitors = (officeId, seatId, monitors) => changeMonitorsRequest(httpMethods.patch)({
        officeId,
        seatId,
        monitors
    }),
    fetchUserBookings = async (employeeId) => {
        const { data } = await bookingService({
            method: httpMethods.get,
            url: `/employee/${employeeId}`,
        })
        return data
    },
    postGroupBooking = async (startDate, endDate, email, seatIds) => groupBookingRequest(httpMethods.post)({
        startDate,
        endDate,
        email,
        seatIds
    })

const fetchOffices = async () => {
    const officeService = axios.create({
        baseURL: `${process.env.REACT_APP_BOOKING_SERVICE}/api/v1`,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json'
        },
    })

    const { data } = await officeService({
        method: httpMethods.get,
        url: `/office`,
    })

    return data
}

export { fetchBookings, postSeatBook, deleteSeatBook, patchSeatBook, patchChangeMonitors, fetchUserBookings, fetchOffices, postGroupBooking }
