import dayjs from 'dayjs'
import 'react-day-picker/lib/style.css'
import { Header, LinkList } from '../../common/components'
import { useOfficeSelection} from '../../common/hooks'
import { routes } from '../../router'
import OfficeContainer from './OfficeContainer'
import OfficeContext from './OfficeContext'
import DateContext from './DateContext'
import { useState, useEffect } from "react"
import './index.css'

function SeatMapView({ isOps }) {
  useEffect(() => {
    document.title = 'Table Booking System';
  }, []);
  
    const [officeName, setOfficeName] = useState('Athlone');
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));   

    const [offices, requestOfficeSelection] = useOfficeSelection();

  return (
    <>
      <OfficeContext.Provider value={{ officeName, setOfficeName }}>
        <DateContext.Provider value={{ date }}>
          <Header>
            <a href='/'><img className='header__logo' src="https://zinkworks.com/wp-content/uploads/2021/09/Zinkworks.png" alt="Zinkworks. logo"/></a>
            Office Table Booking
            <LinkList
              show={isOps}
              paths={[
                  { route: routes.admin, label: 'Admin' }
              ]}
            />
          </Header>          
          <OfficeContainer data={offices}/>
        </DateContext.Provider>            
      </OfficeContext.Provider>
    </>
  )
}

export { SeatMapView }