import T from 'prop-types'
import Button from '@mui/material/Button';
import {ButtonGroup} from "@mui/material";

const AddIntervieweeLinkList = ({show = false, paths, openModal}) => (
  <>
    {show && paths.map((i, idx) => (
      <ButtonGroup key={idx} style={{float: 'right'}} size="small" aria-label="interviewee actions">
        <Button
          style={{
            color: !i.isDisabled ? '#fc688e' : '#929292',
            border: !i.isDisabled ? '1px solid #d0021b66' : '1px solid #929292'
          }} 
          onClick={openModal}
          disabled={i.isDisabled}
          startIcon={< i.icon/>}
          href={`${i.route}`}>
          {`${i.label}`}
        </Button>
      </ButtonGroup>
    ))}
  </>
);

AddIntervieweeLinkList.propTypes = {
  show: T.bool,
  paths: T.arrayOf(T.shape({
    route: T.string.isRequired,
    icon: T.object.isRequired,
    label: T.string.isRequired,
    isDisabled: T.bool.isRequired
  })).isRequired,
  openModal: T.any
}

export default AddIntervieweeLinkList