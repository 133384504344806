import React, {useState} from 'react'
import CloseIcon from '../../common/components/Icons/CloseIcon'
import "../admin/DialogBox/DialogBox.css";
import PropTypes from "prop-types";
import T from "prop-types";
import Moment from 'react-moment';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Card} from "@mui/material";
import { TableSortLabel } from '@mui/material';


function ScoreListDialog(props) {

  const [rowData, setRowData] = useState('');
  const [orderDirection, setOrderDirection] = useState("asc");

  const sortArray = (arr, orderBy) => {
    switch (orderBy) {
      case "asc":
      default:
        return arr.sort((a, b) =>
          a.dateTaken > b.dateTaken ? 1 : b.dateTaken > a.dateTaken ? -1 : 0
        );
      case "desc":
        return arr.sort((a, b) =>
          a.dateTaken < b.dateTaken ? 1 : b.dateTaken < a.dateTaken ? -1 : 0
        );
    }
  };
   
  const handleSortRequest = () => {
    setRowData(sortArray(props.selectedRow.results, orderDirection));
    setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
  };

  const onClose = (e) => {
    props.onClose && props.onClose(e);
  };  
 
  if (!props.show) {
    return null;
  }
  return (
    <div className="modalContainer">
    <span className="backdrop"></span>
      <div className="overlay">
        <div className="modal" id="modal">
          <div className="modalHeader">
            <p style={{color: '#ff0041'}}>Scores List</p>
            <button className="IconButton" onClick={onClose}><CloseIcon ></CloseIcon></button>
          </div>
          <div className="content BaseInput">
            <Card variant="outlined" style={{background: "#f6f6f6e8", padding: "1.5em", color: '#007bff'}}>
              <span>Name: {`${props.selectedRow.fullName}`}</span>
              <p>Email: {`${props.selectedRow.email}`}</p>
            
              <Box sx={{height: 350, width: '100%',  overflow:'auto',  '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none',}}>
                <Table>
                  <TableHead sx={{backgroundColor: '#000c1d'}}>
                    <TableRow>
                      <TableCell sx={{color: '#fff', borderRight: '1px solid #fff'}}>Test Category</TableCell>
                      <TableCell sx={{color: '#fff',  borderRight: '1px solid #fff'}}>Score</TableCell>
                      <TableCell sx={{color: '#fff'}} onClick={handleSortRequest}> Date Taken
                        <TableSortLabel active={true} direction={orderDirection}></TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.selectedRow.results.map((i, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">{i.testCategory}</TableCell>
                        <TableCell>{i.score}</TableCell>
                        <TableCell><Moment format='DD-MM-YY'>{i.dateTaken}</Moment>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>         
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
ScoreListDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  selectedRow: T.objectOf(T.shape({
    email: T.string,
    fullName: T.string.isRequired,
    results: T.arrayOf(T.shape ({
      score: T.string.isRequired,
      dateTaken: T.string.isRequired,
      testCategory: T.string.isRequired,
    }))
}))
};
export default ScoreListDialog
