import {LoginView, SignupView, PwdResetView} from './api/userfront-service'
import {SeatMapView, AdminView} from './views'
import {InterviewView} from './views'
import {DashboardView} from './views'
import AddIntervieweeView from './views/recruitment/addinterviewee/AddIntervieweeView'
import {VerifyEmailView} from "./views/recruitment/interviewtest/VerifyEmailView";

const
    routes = {
        health: '/health',
        login: '/login',
        signUp: '/signup',
        reset: '/reset',
        admin: '/admin',
        recruitment: '/recruitment',
        addInterviewee: '/recruitment/addInterviewee',
        seatMap: '/',
        interview: '/interview'
    },
    router = {
        [routes.health]: () => () => <h3>The App is Healthy</h3>,
        [routes.login]: () => () => <LoginView/>,
        [routes.signUp]: () => () => <SignupView/>,
        [routes.reset]: () => () => <PwdResetView/>,
        [routes.seatMap]: () => (isOps) => <SeatMapView isOps={isOps}/>,
        [routes.admin]: () => (isOps) => <AdminView isOps={isOps}/>,
        [routes.recruitment]: () => () => <DashboardView/>,
        [routes.interview]: () => () => <VerifyEmailView/>,
        [routes.addInterviewee]: () => () => <AddIntervieweeView/>
    }

export {router as default, routes}
