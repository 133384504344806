import { useContext } from 'react'
import { LoadingDots } from '../../common/components'
import { BookStatusContext } from './BookStatusProvider'
import TickerTape from './TickerTape'

function TickerTapeStatus() {
    const { status, error } = useContext(BookStatusContext)

    let content = <LoadingDots length={5}>Loading</LoadingDots>

    if (status.isRejected) {
        content = <div style={{ color: 'red' }}>Booking request failed!</div>
        if (error.status === 403) {
            content = <div style={{ color: 'red' }}>Seat has already been booked!</div>
        }
    }

    return (
        <TickerTape isVisible={status.isLoading || status.isRejected}>
            {content}
        </TickerTape>
    )
}

export default TickerTapeStatus
