import T from 'prop-types'
import styled from '@emotion/styled/macro'

const
    openHeight = 2,
    Tape = styled.div`
        position: fixed;
        bottom: 0;
        right: 0;
        width: 300px;
        height: ${({ open }) => open ? openHeight : 0}em;
        transition: height 2s ease-out 1s;
        background: lightsteelblue;
        border-top-left-radius: 5px;
        & > div:first-of-type {
            position: absolute;
            top: calc(${openHeight}em/2);
            left: 10%;
            margin: 0;
            transform: translateY(-50%);
            color: black;
            font-size: 1rem;
            font-weight: bold;
            white-space: nowrap;
        }
    `

const TickerTape = ({ isVisible, children }) => {

    return (
        <Tape open={isVisible}>
            <div>{children}</div>
        </Tape>
    )
}

TickerTape.propTypes = {
    isVisible: T.bool,
    children: T.oneOfType([T.string, T.element]).isRequired,
}

export default TickerTape