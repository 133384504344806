import React from 'react'
import T from 'prop-types'


const BookStatusContext = React.createContext({ status: { isIdle: true }})

function BookStatusProvider({ status, children }) {
    return (
        <BookStatusContext.Provider value={status}>
            {children}
        </BookStatusContext.Provider>
    )
}

BookStatusProvider.propTypes = {
    status: T.object.isRequired,
    children: T.element.isRequired,
}

export { BookStatusProvider as default, BookStatusContext }
