import React, { useState, useRef, useEffect } from 'react';
import "./DialogBox/DialogBox.css";
import PropTypes from "prop-types";
import CloseIcon from "../../common/components/Icons/CloseIcon";
import { useForm } from 'react-hook-form'
import { useBlockSeat, useChangeMonitor } from '../../common/hooks'
import { toastTimeout } from '../../common/constants';
import { Toast } from '../../common/components';


const toastInitialState = { show: false, message: '' }

export default function ManageSeat(props) {
  const onClose = (e) => {
    props.onClose && props.onClose(e);
  };
  const handleToast = (isVisible, message) => {
    props.handleToast && props.handleToast(isVisible, message);
  }
  const [toast, setToast] = useState(toastInitialState);
  const { register, handleSubmit } = useForm({ shouldUseNativeValidation: true });
  const [officeName, setOfficeName] = useState('1');
  const [seatNumbers, setSeatNumbers] = useState("");
  const [monitorNumber, setMonitorNumber] = useState("");
  const [manageSeatOps] = useBlockSeat();
  const [manageMonitorOps] = useChangeMonitor();

  if (!props.show) {
    return null;
  }

  const
    createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout),
    handleError = (error) => setToast({
      show: true,
      message: <>Error managing seat: <span style={{ color: 'red' }}>{error.message}</span>.</>
    }),

    handleChangeOffice = event => {
      setOfficeName(event.target.value)
    },
    handleChangeSeatNumber = event => {
      setSeatNumbers(event.target.value)
    },
    handleChangeMonitors = event => {
      setMonitorNumber(parseInt(event.target.value))
    };


  const manageSeatStatus = (status) => {
    manageSeatOps(officeName, seatNumbers.split(',').map(i => Number(i)), status).then(
      () => {
        handleToast(
          <>Seat Number '<strong>{seatNumbers}</strong>' is successfully {status ? "unblocked" : "blocked"}</>
        )
        onClose();
      },
      handleError
    ).finally(createToastTimeout);
  }

  const manageMonitorChange = (status) => {
    manageMonitorOps(officeName, seatNumbers.split(',').map(i => Number(i)), monitorNumber).then(
      () => {
        handleToast(
          <>Seat Number '<strong>{seatNumbers}</strong>'' has been successfully changed to have '<strong>{monitorNumber}</strong>' monitors</>
        )
        onClose();
      },
      handleError
    ).finally(createToastTimeout);
  }

  return (
    <>
      <div className="modalContainer">
        <span className="backdrop"></span>
        <div className="overlay">
          <div className="modal" id="modal">
            <div className="modalHeader">
              <p>Manage Seat</p>
              <button className="IconButton" onClick={onClose}><CloseIcon ></CloseIcon></button>
            </div>
            <div className="content BaseInput">
              <form id={'seatNumber'} name="update seat form" onSubmit={handleSubmit()}>
                <select value={officeName} onChange={handleChangeOffice}>
                  {props.data.offices.map((office) => (
                    <option key={office.id} value={office.id}>{office.location}</option>
                  ))}
                </select>
                <div className='InputContainer'>
                  <input
                    type="text"
                    onChange={handleChangeSeatNumber}
                    placeholder="Enter Seat number here"
                    required
                  />
                  <button className='InfoButton'>
                    <svg width="16" height="16" fill="#6c757d" className="bi bi-info" viewBox="0 0 16 16"> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" /> </svg>
                  </button>
                  <div className="hide">You can enter multiple seat numbers using comma. eg: 1,2,3</div>
                </div>
                <div className="monitors">
                  <input
                    type="number"
                    onChange={handleChangeMonitors}
                    placeholder="Enter number of monitors here"
                    required
                  />
                </div>
              </form>
            </div>
            <div className="footer">
              <button className="footer-button" onClick={() => manageSeatStatus(false)}>Block Seat</button>
              <button className="footer-button" onClick={() => manageSeatStatus(true)}> UnBlock Seat</button>
              <button className="footer-button" onClick={() => manageMonitorChange()}> Change Monitors</button>
            </div>
          </div>
        </div>
      </div>
      <Toast visibility={toast.show}>{toast.message}</Toast>
    </>
  );
}
ManageSeat.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

