import React, {useEffect, useState} from 'react';
import { toastTimeout } from '../../common/constants';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from "prop-types";
import 'react-datepicker/dist/react-datepicker.css';
import { LoadingView, Toast, } from '../../common/components';
import { currentUserId } from '../../api/userfront-service';
import { useBookSeat, useSeatMapBookings, useUserBookings } from '../../common/hooks';
import SeatMapContainer from '../seatmap/SeatMapContainer';
import CloseIcon from '../../common/components/Icons/CloseIcon';
import "./DialogBox/DialogBox.css";
import './index.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const toastInitialState = { show: false, message: '' }

export default function GroupBooking({ 
  isOps,
  show,
  onClose,
  data: { offices }, 
 }) {

  const onCloseModal = (closeDialogEvent) => {
    onClose(closeDialogEvent);
  };
 
  const [toast, setToast] = useState(toastInitialState);
  const createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout);
  const handleError = (error) => setToast({
    show: true,
    message: <>Error: <span style={{ color: 'red' }}>{error.message}</span>.</>
  })
  const [userBookings, requestUserBookings] = useUserBookings(currentUserId)
	const [bookings, requestBookings] = useSeatMapBookings(currentUserId)
  const [seatbookings, addSeatBook, removeSeatBook, manageSeatOps, reuqestGroupSeatBookings] = useBookSeat()
  const [officeName, setOfficeName] = useState('Athlone');
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  let [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedSeats, setSelectedSeats] = useState([]);

  const handleChangeOffice = event => {
    setOfficeName(event.target.value);
    if (startDate == null) {
      requestBookings(date, event.target.value);
    }
    else {
      requestBookings(moment(startDate).format('YYYY-MM-DD'), event.target.value);
    }
  };
  
  const handleGroupBookingSeats = (chosenSeats) => {
    let seats = [...selectedSeats];
    seats = chosenSeats;
    setSelectedSeats([...seats]);
  }

  const handleGroupBookingClick = () => {
    const startDateFormat = moment(startDate).format('YYYY-MM-DD');
    const endDateFormat = moment(endDate).format('YYYY-MM-DD');
    reuqestGroupSeatBookings(startDateFormat, endDateFormat, currentUserId, selectedSeats)
    .then(
      onSuccessOpr(),
      handleError
    ).finally(() => {
      requestBookings();
      createToastTimeout();
    })
  }

  const onSuccessOpr = () => {
    setSelectedSeats([])
    requestBookings();
    setToast({
      show: true,
      message: <>Seat Number '<strong>{selectedSeats.toString()}</strong>' reserved!</>
    })
  }

  useEffect(() => {}, [officeName, bookings]);
  if (!show) {
    return null;
  }

  return (
    <>
      <div className="modalContainer GroupBookingModalContainer">
        <span className="backdrop"></span>
        <div className="overlay GroupBookingModalOverlay">
          <div className="modal" id="modal">
            <div className="modalHeader">
              <p>Group Seat Booking</p>
              <button className="IconButton" onClick={onCloseModal}><CloseIcon></CloseIcon></button>
            </div>
            <div className='content modalContent GroupBookingModal'>
              <div className='DateRangeContainer'>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  withPortal
                  placeholderText={'Choose Date Range'}
                  isClearable
                  showIcon
                  dateFormat="yyyy/MM/dd"
                />
              </div>
              <FormControl fullWidth sx={{marginBottom: '.75em'}}>
                <InputLabel id="office-select-label">Select Office</InputLabel>
                <Select
                  labelId="office-select-label"
                  id="office-name-select group-booking-office-name"
                  label="Select Office"
                  value={officeName}
                  onChange={handleChangeOffice}
                >
                  {offices.map((office) => (
                    <MenuItem key={office.id} value={office.name}>{office.location}</MenuItem>
                  ))}
                </Select>

              </FormControl>  
              <SeatMapContainer selectedSeats= {selectedSeats} setSelectedSeats={handleGroupBookingSeats} isOps={isOps} data={bookings} onSuccessOpr={requestUserBookings} />
              { selectedSeats.length > 0 &&
                <>
                  <InputLabel sx={{background: 'rgba(0, 0, 0, 0.05)', padding: '0.5em', borderRadius: '5px'}}>{`Selected Seats: ${selectedSeats.toString()}`}</InputLabel>
                </>
              }
            </div>
            <div className="footer modalFooter GroupBookingModalFooter">
              <button className='footer-button' onClick={handleGroupBookingClick}>Reserve</button>
              <button className="footer-button cancel-button"  onClick={onCloseModal}>Cancel</button>
            </div>
          </div>
          <Toast visibility={toast.show}>{toast.message}</Toast>
        </div>
      </div>
    </>
  )
}