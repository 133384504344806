import T from 'prop-types'
import styled from '@emotion/styled/macro'
import Row from './Row'
import Seat from './Seat'
import TickerTapeStatus from './TickerTapeStatus'
import Kitchen from '../../common/components/Kitchen'
import MeetingRoom from '../../common/components/MeetingRoom'


const Blank = styled.div`
    width: 52px;
    flex: none;
`
const isSeatAvailable = seat => (seat?.isEnabled ?? true) && !seat?.isReserved && !seat?.isSelected

function buildSeatList(seats, onAddSeat, onRemoveSeat, isOps) {
    let totalAvailableSeats = 0
    const seatList = seats.map(seat => {
        isSeatAvailable(seat) && ++totalAvailableSeats
        return seat?.label
            ? <Seat
                key={seat.id}
                {...seat}
                isOps={isOps}
                onAddSeat={() => onAddSeat(seat.row, seat.id)}
                onRemoveSeat={() => onRemoveSeat(seat.row, seat.id)}
                monitors = {seat.monitors}
              />
            : <Blank key={seat.label} />
    })
    return { seatList, hasSeatAvailable: !!totalAvailableSeats }
}

function buildRow(row, seats, onAddSeat, onRemoveSeat, isOps) {
    const { seatList, hasSeatAvailable } = buildSeatList(seats, onAddSeat, onRemoveSeat, isOps)
    row.isSelected = !hasSeatAvailable
    
    return (
        <Row key={row.id} {...row}>
            {seatList}
        </Row>
    )
}

const SeatMap = ({ data, onAddSeat, onRemoveSeat, isOps }) => (
    <>
        {data.map(({ seats, ...row }) => buildRow(row, seats, onAddSeat, onRemoveSeat, isOps))}
        <TickerTapeStatus />
    </>
)

SeatMap.propTypes = {
    data: T.arrayOf(T.object).isRequired,
    onAddSeat: T.func.isRequired,
    onRemoveSeat: T.func.isRequired,
}

export default SeatMap