import React from 'react';
import styled from '@emotion/styled/macro';

const TimerWrapper = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin: 10px 0;
        width: 100%;
    `

const Timer = ({elapsedTime}) => {
    const convertHMS = () => {
        // const sec = parseInt(value, 10); // convert value to number if it's string
        // let hours   = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor(elapsedTime / 60); // get minutes
        let seconds = elapsedTime - (minutes * 60); //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        // if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes+':'+seconds; // Return is HH : MM : SS
    }
    return <TimerWrapper>{convertHMS()}</TimerWrapper>
};

export {Timer}
