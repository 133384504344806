import React, {useState} from 'react';
import { LinkList } from '../../../common/components';
import { routes } from '../../../router';
import { useOfficeSelection} from '../../../common/hooks'
import './NavBar.css';
import PersonIcon from '../../../common/components/Icons/PersonIcon';
import ManageSeat from '../ManageSeat';
import GroupBooking from '../GroupBooking';

export default function Navbar({isOps, handleToast})  {
  const [show, setShow] = useState(false);
  const [showGroupBooking, setShowGroupBooking] = useState(false);
   const showModal = () => {
    setShow(!show);
  }
  const showGroupReservationModal = () => {
    setShowGroupBooking(!showGroupBooking);
  }

  const handleToastMessage = (message) => {
    handleToast(message);
  }
  const [offices, requestOfficeSelection] = useOfficeSelection();

  return  (
    <>
      <div className='nav'>
        <div className='nav__contents'>
          <div className='nav__links'>
            <a href='/'><img className='nav__logo' src="https://zinkworks.com/wp-content/uploads/2021/09/Zinkworks.png" alt="Zinkworks. logo"/></a>
            <LinkList
              show={isOps}
              paths={[
                  { route: routes.admin, label: 'Admin' },
                  { route: routes.recruitment, label: 'Recruitment' },
              ]}
            />
            <div className='group-booking manage-seats' onClick={e => {showGroupReservationModal()}}>Group Reservation</div>
            <div className='manage-seats' onClick={e => {showModal()}}>Manage Seats</div>
          </div>
          <div className='admin'>
            <PersonIcon /><span>Admin</span>
          </div>
        </div>
      </div>
      <GroupBooking isOps={isOps} show={showGroupBooking} onClose={showGroupReservationModal} data={offices} handleToast={handleToastMessage}></GroupBooking>
      <ManageSeat show={show} onClose={showModal} data={offices} handleToast={handleToastMessage}></ManageSeat>
    </>
  )
}