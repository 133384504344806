import T from 'prop-types'
import styled from '@emotion/styled/macro'
import { useCounterUp } from '../hooks'

const Container = styled.div`
  width: 75px;
`

function LoadingDots({ delay, length, children }) {
  const count = useCounterUp(delay, length) // Custom Hook

  return <Container>{children + '.'.repeat(count)}&nbsp;</Container>
}

LoadingDots.propTypes = {
  delay: T.number,
  length: T.number,
  children: T.string.isRequired,
}

export default LoadingDots