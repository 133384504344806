import T from 'prop-types'
import styled from '@emotion/styled/macro'
import {useEffect, useState} from 'react'
import {FetchingMain, RejectedMain, LoadingDots} from '../../../common/components'
import {devConsoleLog} from '../../../api/util'
import {usePostInterview} from '../../../common/hooks'
import {Toast} from '../../../common/components'
import {toastTimeout} from '../../../common/constants'
import Button from "@mui/material/Button";
import {Container, Grid, LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import * as React from "react";
import Typography from "@mui/material/Typography";
import {useCountdown} from "../../../common/hooks/countdown";
import {Timer} from "../../../common/hooks/Timer";

const
    FormContainer = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3em;
      border-radius: 5px;
      background-color: #FFFFFF;
      box-shadow: -5px 10px 20px 3px rgb(0 0 0 / 6%);
    `,
    Label = styled.label`
      display: block;
      flex-direction: column;
      margin: 5px 0;
      font-size: 24px;
      font-family: "Arial";

    `,
    InputRadioButton = styled.input`
      left: 0.5rem;
      top: 0.2rem;
      display: inline-block;
      border: 0.1rem solid black;
      background: white;
      transform: scale(1.3);
    `


function InterviewTestView({data: {status, error, interviewTest}, email, uuid, openModal}) {
    const ref = React.useRef(null);
    const TEST_TIME = 35;
    const {counter: elapsedTime, reset, stop} = useCountdown(TEST_TIME);
    const [selectInterviewTest, addInterviewTest] = usePostInterview();
    const [questionNumber, setQuestionNumber] = useState(0);
    const [count, setCount] = useState(0);
    const currentQuestion = interviewTest[questionNumber];
    const [correctChoice, setCorrectChoice] = useState(false);
    const [state, setState] = useState(false);
    const [leftPercentage, setLeftPercentage] = useState(100);
    const testCatalog = interviewTest[0]['catalog'];

    //create toast
    const toastInitialState = {show: false, message: ''}
    const [toast, setToast] = useState(toastInitialState),
        createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout);
    const handleError = (error) => setToast({
        show: true,
        message: <>Error Submitting Test: <span style={{color: 'red'}}>{error.message}</span></>
    });

    useEffect(() => {
      document.title = 'Interview Test';

        if (questionNumber === 0) {
            ref.current?.start();
        } else {
            ref.current?.restart();
        }
    }, [questionNumber])

    function handleTimerFinish() {
        if (questionNumber === 10) {
            ref.current?.stop();
            submitTest();
        } else {
            nextQuestion();
        }
    }

    function handleAddInterviewTest(count) {
        addInterviewTest(email, uuid, count, testCatalog)
            .then(
                () => {
                    setToast({
                        show: true,
                        message: <strong>Test Submitted</strong>
                    })
                },
                handleError
            ).finally(createToastTimeout)
            .catch((error) => console.log(error))
    }

    const handleChange = (questionId, answer) => {
        devConsoleLog(interviewTest[questionId].correctAnswer)
        if (interviewTest[questionId].correctAnswer.toUpperCase() === answer.toUpperCase()) {
            setCorrectChoice(true)
        } else {
            setCorrectChoice(false)
        }
    }

    const checkCorrectAnswer = () => {
        if (correctChoice === true) {
            setCount(c => c + 1)
            devConsoleLog(count)
            setCorrectChoice(false)
        }
    }

    const nextQuestion = () => {
        reset()
        checkCorrectAnswer()
        if (questionNumber < 10) {
            setQuestionNumber(c => c + 1)
        }
    }

    const submitTest = () => {
        stop()
        checkCorrectAnswer()
        handleAddInterviewTest(count)
        setState(true)
    }

    const controlTime = () => {
        if (elapsedTime <= 0 && questionNumber < 9) {
            nextQuestion()
        }
        if (elapsedTime <= 0 && questionNumber === 9) {
            submitTest()
        }
    }

    useEffect(() => {
        const onePercent = TEST_TIME/100;
        setLeftPercentage(elapsedTime/onePercent);
        if (elapsedTime >= 0) {
            controlTime()
        }
    }, [elapsedTime])

    if (status.isLoading) return (
        <FetchingMain>
            <label>
                <LoadingDots length={5}>Loading</LoadingDots>
                <progress/>
            </label>
        </FetchingMain>
    )

    if (status.isRejected) return (
        <RejectedMain>
            <p>Error fetching interviewee list: {error.response?.data ?? error.message}</p>
        </RejectedMain>
    )

    if (status.isResolved) return (
        <>
				  <Box sx={{ marginTop: '2em', width: '100%', }}>
            {state ? (<>
              <Container style={{ transform: 'scale(0.8)', padding: '2em', textAlign: 'center' }}>
                <h1 style={{textAlign: 'center', fontWeight: 'bold', marginBottom: '1em'}}>
                  Thanks for taking the test. 
                </h1>
                <Typography>Recruiter will be in contact with you shortly</Typography>
              </Container>
              <Toast visibility={toast.show}>{toast.message}</Toast>
            </>) :

            (<> {interviewTest.map((question, index) => (index === questionNumber ? (
              <Container style={{ maxWidth: '100%', marginBottom: '15px', padding: '0'}}>
                <Typography variant="h6" component="h2" style={{textAlign: 'center', padding: '1em'}}>
                  Please answer the questions below
                </Typography>
                <Grid container spacing={2} style={{marginBottom: "15px", padding: '1em'}}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        width: "100%",
                        height: 50,
                        backgroundColor: '#f6f6f6e8',
                        border: '1px solid #0000001f'
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Typography
                            style={{marginTop: "15px", marginLeft: "12px"}}
                            variant="body2"
                            color="text.secondary"
                          >
                            {`Question: ${questionNumber + 1}/10`}
                          </Typography>
                        </Grid>
                        <Grid item xs={9} style={{pointerEvents: "none"}}>
                          <>
                            <LinearProgress
                                variant="determinate"
                                value={leftPercentage}
                                dir="ltr"
                                style={{ height: "2.7rem", marginTop: "3px", marginRight: "3px" }}
                                className={` w-75 mx-auto`}
                            />
                            <div style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}>
                              <span style={{ textColor: "black", fontSize: "15px", display: "flex", justifyContent: "center", marginTop: "7px" }}>
                                <Timer elapsedTime={elapsedTime}/>
                              </span>
                            </div>
                          </>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        width: "100%",
                        height: 50,
                        backgroundColor: '#f6f6f6e8',
                        border: '1px solid #0000001f',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Button
                        style={{color: '#000000'}}
                        startIcon={<FormatListBulletedIcon/>}
                        onClick={openModal}
                      >
                        Instructions
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <FormContainer  style={{boxShadow: "none", padding: '2em'}}>
                  <div key={questionNumber} data-hidden={questionNumber !== currentQuestion[questionNumber]}>
                    <Label style={{marginBottom: '10px'}}>
                      {questionNumber + 1}. {question.question}
                    </Label>
                    <Label>
                      <InputRadioButton
                        type='radio'
                        value='a'
                        name={currentQuestion.answer_a}
                        onChange={() => handleChange(questionNumber, 'a')}
                        style={{marginRight: '10px'}}
                      /> 
                      A. {currentQuestion.answer_a}
                    </Label>
                    <Label>
                      <InputRadioButton
                        type='radio'
                        value='b'
                        name={currentQuestion.answer_a}
                        onChange={() => handleChange(questionNumber, 'b')}
                        style={{marginRight: '10px'}}
                      />
                      B. {currentQuestion.answer_b}
                    </Label>
                    <Label>
                      <InputRadioButton
                        type='radio'
                        value='c'
                        name={currentQuestion.answer_a}
                        onChange={() => handleChange(questionNumber, 'c')}
                        style={{marginRight: '10px'}}
                      /> 
                      C. {currentQuestion.answer_c}
                    </Label>
                    <Label>
                      <InputRadioButton
                        type='radio'
                        value='d'
                        name={currentQuestion.answer_a}
                        onChange={() => handleChange(questionNumber, 'd')}
                        style={{marginRight: '10px'}}
                      />
                      D. {currentQuestion.answer_d}
                    </Label>

                    {questionNumber + 1 < 10 ? (
                      <Button style={{
                          color: '#FFFFFF',
                          backgroundColor: '#00a90f',
                          border: '1px solid #00a90f',
                          marginTop: '1em'
                      }} onClick={nextQuestion} variant="contained"> Next </Button>
                    ) : (
                      <Button style={{
                          color: '#FFFFFF',
                          backgroundColor: '#ff0041',
                          border: '1px solid #ff0041',
                          marginTop: '1em'
                      }} onClick={submitTest} variant="contained"> Submit </Button>
                    )}
                  </div>
                </FormContainer>
              </Container>
            ) : (null)
            ))}
            </>)}
          </Box>
        </>
    )
    return null
}

InterviewTestView.propTypes = {
    data: T.shape({
        interviewTest: T.array.isRequired,
        status: T.object.isRequired,
        error: T.object,
    }),
    email: T.string.isRequired,
    uuid: T.string.isRequired,
    openModal: T.any
}

export default InterviewTestView
