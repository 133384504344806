import T from 'prop-types'
import styled from '@emotion/styled/macro'
import { A } from '@patched/hookrouter'

const
  NavBarLinks = styled.ul`
    margin: 0;
    padding:0;
      list-style: none;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-flow: wrap;
  `,
  ListItem = styled.li`
    display:inline;
    margin-right: 1em;
    cursor: pointer;
    border-right: 2px solid #0d6efd;
    padding: 0 20px;

  `,
  StyledLink = styled(A)`
    font-size: 1.2rem; 
    font-weight: normal;
    color: #0d6efd;
    text-decoration: none;

    :visited{ 
        color: #0d6efd
    }

    :hover{
        cursor:pointer;
        color: rgb(245, 85, 79);
        filter: brightness(1.1);
        cursor:pointer;
        transition: all 0.3s ease;
    } 
  `
const LinkList = ({ show = false, paths }) => (
  <NavBarLinks className='navbar__links'>
    {show && paths.map((i, idx) => (
      <ListItem className='navbar__links--item' key={idx}>
        <StyledLink href={`${i.route}`}>
          {`${i.label}`}
        </StyledLink>
      </ListItem>
    ))}
  </NavBarLinks>
)

LinkList.propTypes = {
  show: T.bool,
  paths: T.arrayOf(T.shape({
      route: T.string.isRequired,
      label: T.string.isRequired,
  })).isRequired,
}

export default LinkList