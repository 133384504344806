import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Card, Typography} from "@mui/material";
import Modal from '@mui/material/Modal';
import T from "prop-types";
import {Grid} from "@mui/material";
import {useState} from "react";
import {useIntervieweeProfile} from "../../../common/hooks";
import {toastTimeout} from "../../../common/constants";
import {Toast} from "../../../common/components";
import {FetchingInputs} from '../../../common/components'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const toastInitialState = {show: false, message: ''}
function RemoveIntervieweeModalView({open, closeModel, selectedRow}) {
 
    const
        {deleteInterviewee} = useIntervieweeProfile(),
        [toast, setToast] = useState(toastInitialState),
        createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout);
    const handleError = (error) => setToast({
        show: true,
        message: <>Error removing interviewee: <span style={{color: 'red'}}>{error.message}</span></>
    });
    function handleRemove(interviewee) {
        deleteInterviewee({email: interviewee})
            .then(
                () => {
                    setToast({
                        show: true,
                        message: <>Interviewee '<strong>{interviewee}</strong>'' deleted!</>
                    });
                    window.location.reload();
                },
                handleError
            ).finally(createToastTimeout)
            .catch((error) => console.log(error))
    }

    return (
        <div>
            <Toast visibility={toast.show}>{toast.message}</Toast>
            <Modal
                open={open}
                onClose={closeModel}
                aria-labelledby="Remove Interviewee"
            >
              <Box sx={style}>
                <Typography variant="h6" component="h2" style={{textAlign: 'center',marginBottom: '.5em'}}>
                    Remove Interviewee
                </Typography>
                <Card variant="outlined" style={{background: "#f6f6f6e8", padding: "1.5em"}}>
                  <FetchingInputs>
                    {selectedRow && selectedRow.map((i, idx) => (
                        <Grid container spacing={2} key={idx}>
                            <Grid item xs={4}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Name:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography style={{wordBreak: 'break-all'}} id="modal-modal-title" variant="h6"
                                            component="h2">
                                    {`${i.fullName}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    E-mail:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography style={{wordBreak: 'break-all'}} id="modal-modal-title" variant="h6"
                                            component="h2">
                                    {`${i.email}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Are you sure you want to delete the Interviewee?
                                </Typography>
                            </Grid>
                            <Box display='flex' width='1' mt={3} flexDirection='row' justifyContent='center' boxShadow='none'>
                                <Button style={{
                                    color: '#FFFFFF',
                                    backgroundColor: '#ff0041',
                                    border: '1px solid #ff0041',
                                }} onClick={() => handleRemove(i.email)}>Delete</Button>
                                <Button style={{
                                    color: '#FFFFFF',
                                    backgroundColor: '#c4bfbe',
                                    border: '1px solid #c4bfbe',
                                    marginLeft: '10px'
                                }} onClick={closeModel}>Cancel</Button>
                            </Box>
                        </Grid>
                    ))}
                  </FetchingInputs>
                </Card>
              </Box>
            </Modal>
        </div>
    );
}

RemoveIntervieweeModalView.propTypes = {
    open: T.bool.isRequired,
    closeModel: T.any,
    selectedRow: T.arrayOf(T.shape({
        email: T.string.isRequired,
        fullName: T.string.isRequired,
        id: T.string.isRequired,
        uuid: T.string.isRequired
    }))
}

export {RemoveIntervieweeModalView};