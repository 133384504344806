import React, {useState, useContext} from 'react';

import T from 'prop-types'
import { OfficeMain } from '../../common/components'
import { devConsoleLog } from '../../api/util'
import { currentUserId, systemLogout } from '../../api/userfront-service'
import { Sidebar } from '../../common/components'
import OfficeContext from './OfficeContext';
import dayjs from 'dayjs'
import DayPicker from 'react-day-picker'
import styled from '@emotion/styled/macro'
import 'react-day-picker/lib/style.css'
import { useSeatMapBookings, useUserBookings } from '../../common/hooks'
import SeatMapContainer from './SeatMapContainer'
import BookListStatus from './BookListStatus'
import Legend from './Legend'
import { off } from 'process';

const
  Button = styled.button`
      display: block;
      margin: 20px;
      margin-top: 0px;
      margin-bottom: 20px;
      cursor: pointer;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      user-select: none;
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1rem;
      border-radius: .25rem;
      transition: color .15s ease-in-out,
          background-color .15s ease-in-out,
          border-color .15s ease-in-out,
          box-shadow .15s ease-in-out;
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;

      &:hover {
          background-color: #5c636a;
          border-color: #565e64;
      }

      &:focus {
          outline: 0;
          background-color: #5c636a;
          border-color: #565e64;
          box-shadow: 0 0 0 .25rem rgba(130,138,145,.5);
      }

      &:active {
          background-color: #565e64;
          border-color: #51585e;
      }
  `

const OfficeContainer = ({data: { status, error, offices }}) => { 
	const { officeName, setOfficeName } = useContext(OfficeContext)
	const [date, setDate] = useState();    
	const [bookings, requestBookings] = useSeatMapBookings(currentUserId)
	const [userBookings, requestUserBookings] = useUserBookings(currentUserId)

	const handleDayClick = (date) =>{
		const isoDate = dayjs(date).format('YYYY-MM-DD')
		devConsoleLog('handleDayClick', isoDate)        
		setDate(isoDate)
		renderBookings(isoDate, officeName)
	}    

	const handleChange = event => {
		setOfficeName(event.target.value)
		renderUserBookings(event.target.value)        
		renderBookings(date,event.target.value)
		requestUserBookings(event.target.value)
	}

	function renderBookings(selectedDate, selectedOffice){        
		requestBookings(selectedDate, selectedOffice)
	}

	function renderUserBookings(selectedOffice) {
		if (bookings.status.isResolved) {
			if (userBookings.status.isIdle) {
				requestUserBookings(selectedOffice) // fetch currentUserBookings
			} else {
				return <BookListStatus data={userBookings} />
			}
		}
		return <BookListStatus data={userBookings} />
	}
    
	return (
		<>
			<OfficeMain>
				<div>           
						<p> Select Office </p>
						<select value={officeName} onChange={handleChange} name="office-selection"> 
								{offices.map((office) => (
										<option key={office.id} value={office.name}>{office.location}</option>
								))}
						</select>                     
				</div>
			</OfficeMain> 
			<Sidebar>
				<DayPicker
						onDayClick={handleDayClick}
						selectedDays={ 
							bookings.isoDate ? dayjs(bookings.isoDate).toDate() : undefined
						}
				/>
				<Button onClick={systemLogout}>Logout</Button>
				{renderUserBookings(officeName)}
				<Legend />
			</Sidebar>       
			<SeatMapContainer data={bookings} onSuccessOpr={requestUserBookings} selectedOffice={officeName} />
		</>
	);
}

OfficeContainer.propTypes = {
	data: T.shape({
		offices: T.array.isRequired,
		status: T.object.isRequired,
		error: T.object,
	})
}
export default OfficeContainer;