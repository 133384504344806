import T from 'prop-types'
import {FetchingMain, LoadingDots, RejectedMain} from '../../common/components'
import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import { useEffect } from 'react';
import ScoreListDialog from './ScoreListDialog';

function IntervieweeList({data: {status, error, interviewees}, selectedRows}) {
  const [show, setShow] = useState(false);
  const showScoreDialog = () => {
   setShow(!show);
 };
  
 useEffect(() => {
    document.title = 'Recruitment';
  }, [])
  const [selectedRow, setSelectedRow] = useState();

  const columns = [
    {
      field: 'fullName',
      headerName: 'Name',
      editable: false,
      flex: 1,
      sortable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
      sortable: true,
    },
    {
      field: 'score',
      headerName: 'Score',
      editable: false,
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        if (params.row.results[0]) {
          return <>
            <button 
              style={{appearance: 'none', border: 'none', background: 'none', cursor: 'pointer', textDecoration: 'underline'}} 
              onClick={e => {
                setSelectedRow(params.row);
                showScoreDialog()}}>
                Show Scores
            </button>
          </>
        }
        else
          return <span 	style={{color: '#ff0041'}}><em>Test Pending</em></span>
      },
    },
  ];
  

    if (status.isLoading) return (
        <FetchingMain>
            <label>
                <LoadingDots length={5}>Loading</LoadingDots>
                <progress/>
            </label>
        </FetchingMain>
    )

    if (status.isRejected) return (
        <RejectedMain>
            <p>Error fetching interviewee list: {error.response?.data ?? error.message}</p>
        </RejectedMain>
    )

    if (status.isResolved)
        if (interviewees && interviewees.length > 0) {
            for (const key in interviewees) {
                interviewees[key].id = key + 1;
            }
        }
    return (
      <>
        <Box sx={{height: 800, width: '100%'}}>
            <DataGrid
              rowHeight={52}
              rows={interviewees}
              columns={columns}
              pageSize={14}
              rowsPerPageOptions={[14]}
              onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = interviewees.filter((row) => selectedIDs.has(row.id.toString()));
                  selectedRows(selectedRowData);
              }}
            />
        </Box>
        <ScoreListDialog show={show} onClose={showScoreDialog} selectedRow={selectedRow}></ScoreListDialog>
      </>
    )
}

IntervieweeList.propTypes = {
    data: T.shape({
        interviewees: T.array.isRequired,
        status: T.object.isRequired,
        error: T.object,
    }),
    selectedRows: T.func.isRequired
}

export default IntervieweeList