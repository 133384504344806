import React from 'react'
import CloseIcon from '../../../common/components/Icons/CloseIcon'

const DeleteConfirmation = (props) => {
  if (!props.show) {
    return null;
  }
  return (
    <div className="modalContainer deleteDialog">
      <span className="backdrop"></span>
    
      <div className="overlay">
          <div className="modal" id="modal">
            <div className="modalHeader">
              <p>Delete Employee</p>
              <button className="IconButton" onClick={props.hideModal}><CloseIcon></CloseIcon></button>
            </div>
            <div className="content">
              <div className="alert alert-danger">Are you sure want to remove {props.employee.fullName}?</div>
            </div>
            <div className="footer">
              <button className="footer-button cancel-button"  onClick={props.hideModal}>Cancel</button>
              <button className="footer-button delete-button" onClick={() => props.onConfirm() }>Delete</button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default DeleteConfirmation