import React from 'react'

export default function HrIcon({...props}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox='0 0 64 64' aria-labelledby="title" aria-describedby="desc" role="img" >
      <circle data-name="layer2"
      cx="32" cy="39" r="7" fill="none" stroke="currentColor" strokeMiterlimit="10"
      strokeWidth="4" strokeLinejoin="round" strokeLinecap="round"></circle>
      <path data-name="layer2" d="M32 46a12.1 12.1 0 0 0-12 12v2h24v-2a12.1 12.1 0 0 0-12-12z"
      fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="4" strokeLinejoin="round"
      strokeLinecap="round"></path>
      <circle data-name="layer2" cx="52" cy="10" r="6" fill="none" stroke="currentColor"
      strokeMiterlimit="10" strokeWidth="4" strokeLinejoin="round" strokeLinecap="round"></circle>
      <path data-name="layer2" d="M62 28c0-7.5-4.5-12-10-12s-10 4.5-10 12z"
      fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="4" strokeLinejoin="round"
      strokeLinecap="round"></path>
      <circle data-name="layer2" cx="12" cy="10" r="6" fill="none" stroke="currentColor"
      strokeMiterlimit="10" strokeWidth="4" strokeLinejoin="round" strokeLinecap="round"></circle>
      <path data-name="layer2" d="M22 28c0-7.5-4.5-12-10-12S2 20.5 2 28z"
      fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="4" strokeLinejoin="round"
      strokeLinecap="round"></path>
      <path data-name="layer1" fill="none" stroke="currentColor" strokeMiterlimit="10"
      strokeWidth="4" d="M12 34l8 8m32-8l-8 8M24 14h16" strokeLinejoin="round"
      strokeLinecap="round"></path>
    </svg>
  )
}