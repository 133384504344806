import T from 'prop-types'
import styled from '@emotion/styled/macro'


const
    Container = styled.div`
        background-color: ${({ isSelected }) => isSelected ? '#f5f5f5' : 'inherit'};
        font-family: "Helvetica";
        width: fit-content;
        flex: none;
        display: flex;
        align-items: center;
        border-radius: 4px;

        &:hover {
            background-color: #f5f5f5;
        }
    `,
    Label = styled.div`
        font-weight: 700;
        width: 35px;
        flex: none;
        text-align: center;
        color: #9E9E9E;
    `,
    Tail = styled.div`
        width: 35px;
        flex: none;
    `

function Row({ label, isSelected = false, children }) {
    return (
        <Container isSelected={isSelected}>
            <Label>{label}</Label>
            {children}
            <Tail />
        </Container>
    )
}

Row.propTypes = {
    label: T.oneOfType([T.string, T.number]).isRequired,
    isSelected: T.bool,
    children: T.arrayOf(T.element),
}

export default Row
