import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Card} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import T from "prop-types";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function DisclaimerModalView ({open, closeModel}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={closeModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h6" component="h2" style={{textAlign: 'center'}}>
                        Disclaimer
                    </Typography>
                    <Card variant="outlined" style={{background: "#f6f6f6e8", height: "15rem"}}>
                        <ul>
                            <li>Content</li>
                        </ul>
                    </Card>
                    <div style={{display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50px"}}>
                        <Button style={{
                            color: '#FFFFFF',
                            backgroundColor: '#ff0041',
                            border: '1px solid #ff0041'
                        }} onClick={closeModel} variant="contained">Close</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}


DisclaimerModalView.propTypes = {
    open: T.bool.isRequired,
    closeModel: T.any,
}

export {DisclaimerModalView}
