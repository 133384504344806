import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import T from "prop-types";
import {Grid} from "@mui/material";
import {useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {useIntervieweeProfile} from '../../../common/hooks'
import {v4 as uuidv4} from 'uuid';
import { toastTimeout } from '../../../common/constants';
import { Toast } from '../../../common/components';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const toastInitialState = {show: false, message: ''}

function ActivateTestModelView({open, closeModel, selectedRow}) {
    const [isCopied, setIsCopied] = useState(false);
    const host = window.location.protocol + "//" + window.location.host + "/interview?userId=";
    const [testCategory, setTestCategory] = React.useState('');
    const {patchInterviewee} = useIntervieweeProfile();
    const [disableCopyLink, setActivateCopyLink] = useState(true);
    const [toast, setToast] = useState(toastInitialState);
    const createToastTimeout = () => setTimeout(() => setToast(toastInitialState), toastTimeout);
    const [interviewUuid, setInterviewUuid] = useState("");
    const handleError = (error) => setToast({
      show: true,
      message: <>Error activating test for interviewee: <span style={{color: 'red'}}>{error.message}</span> <br/></>
    });
    const handleTestCategory = event => {
      setTestCategory(event.target.value);
    };
    
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = () => {
        const copyText = `${host}${interviewUuid}`
        copyTextToClipboard(copyText)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

		const handleActivateClick = () => {
      let interviewee = {
        "fullName" : selectedRow[0].fullName,
        "email" : selectedRow[0].email,
        "uuid" : selectedRow[0].uuid === "" ? uuidv4() : selectedRow[0].uuid,
        "scheduledTest" : testCategory
      };
      patchInterviewee(interviewee)
      .then(
          () => {
            setInterviewUuid(interviewee.uuid);
            setToast({
              show: true,
              message: <>Test is activated!</>
            })
            setActivateCopyLink(false);
          },
          handleError
          ).finally(createToastTimeout)
      .catch((error) => console.log(error));
		};
    
    return (
      <>
        <Modal
          open={open}
          onClose={closeModel}
          aria-labelledby="Activate Test"
        >
          <Box sx={style}>
            <Typography variant="h6" component="h2" style={{textAlign: 'center'}}>
              Activate Test
            </Typography>
            {selectedRow && selectedRow.map(({fullName, email}, idx) => (
              <Grid container spacing={2} key={idx}>
                <Grid item xs={3}>
                    <Typography variant="h6" component="h2">
                      Name:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography style={{wordBreak: 'break-all'}} variant="h6" component="h2">
                    {fullName}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="h6" component="h2">
                    E-mail:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography style={{wordBreak: 'break-all'}} variant="h6" component="h2">
                    {email}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" component="h2">
                    Test Category:
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel id="test-category-select-label">Test Category</InputLabel>
                    <Select
                      labelId="test-category-select-label"
                      id="test-category-select"
                      label="Test Category"
                      value={testCategory}
                      onChange={handleTestCategory}
                    >
                      <MenuItem value={"Java"}>Java</MenuItem>
                      <MenuItem value={"DevOps"}>DevOps</MenuItem>
                      <MenuItem value={"Front-End Development"}>Front-End Development</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
  
                <Grid item width='1' display='flex' mt={3} flexDirection='row' justifyContent='center' boxShadow='none' style={{paddingTop:'0'}}>
                  <Button style={{
                      color: '#FFFFFF',
                      backgroundColor: '#ff0041',
                      border: '1px solid #ff0041',
                    }}
                    onClick={handleActivateClick} 
                  >
                    Activate Test
                  </Button>
                  <Button style={{
                      color: '#FFFFFF',
                      backgroundColor: '#c4bfbe',
                      border: '1px solid #c4bfbe',
                      marginLeft: '10px'
                    }} onClick={closeModel}>
                      Cancel
                  </Button>
                </Grid>
                
                {interviewUuid !== "" && 
                  <Grid item width='1' display='flex' mt={3} flexDirection='row' justifyContent='center' style={{paddingTop:'0'}}>
                    <TextField
                      fullWidth id="outlined-read-only-input"
                      defaultValue={`${host}${interviewUuid}`}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Button disabled={disableCopyLink} className='CopyLinkButton' style={{
                      fontSize: '12px',
                      color: '#FFFFFF',
                      backgroundColor: '#ff0041',
                      border: '1px solid #ff0041',
                      borderRadius: '0 4px 4px 0'
                    }} 	onClick={handleCopyClick} >
                      <span>{isCopied ? 'Copied!' : 'Copy Link'}</span>
                    </Button>
                  </Grid>
                }
              </Grid>
            ))}
          </Box>
        </Modal>
        <Toast visibility={toast.show}>{toast.message}</Toast>
      </>
    );
}

ActivateTestModelView.propTypes = {
    open: T.bool.isRequired,
    closeModel: T.any,
    selectedRow: T.arrayOf(T.shape({
        email: T.string.isRequired,
        fullName: T.string.isRequired,
        id: T.string,
        uuid: T.string.isRequired,
    })).isRequired
}

export {ActivateTestModelView};