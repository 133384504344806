import styled from '@emotion/styled/macro'
import { PuffLoader } from 'react-spinners'

const Container = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LoadingView = () => (
    <Container>
        <PuffLoader color="blue" />
    </Container>
)

export default LoadingView